<template>
  <v-dialog width="500" v-model="showConfirmationDialogue">
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Send to SAP</span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showConfirmationDialogue = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card class="elevation-12 rounded-xl">
        <v-card-text class="pa-4">
          <v-row class="mt-2">
            <v-col cols="12" lg="12" v-if="localPostDate">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Post Date',
                  id: 'post-form-date',
                  clearable: false,
                  'hide-details': 'auto',
                  'error-messages': formErrors.post_date || [],
                }"
                :disabled="!localPostDate"
                v-model="localPostDate"
                @input="delete formErrors.post_date"
              />
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col cols="12" lg="10">
              <!-- <p class="text-h5 font-weight-medium mb-2 text-center">
                {{ title }}
              </p> -->
              <p class="text-body-1 text-center text-subtitle-2 mb-0">
                {{ text }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end py-4">
          <v-btn depressed color="grey" @click="reject" class="mr-3">
            {{ rejectButton }}
          </v-btn>
          <v-btn depressed color="primary" @click="confirmed">
            {{ confirmButton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import DateField from "@/components/FormBaseComponents/DateField.vue";

export default {
  components: {
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    postDate: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "mdi-information",
    },
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "Text",
    },
    confirmButton: {
      type: String,
      default: "Yes",
    },
    rejectButton: {
      type: String,
      default: "No",
    },
  },
  computed: {
    showConfirmationDialogue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isPostDateValid() {
      return this.postDate && this.postDate.trim() !== "";
    },
  },
  data() {
    return {
      filters: {
        start_date: null,
      },
      formErrors: {},
      localPostDate: null,
    };
  },
  watch: {
    showConfirmationDialogue(newValue) {
      if (newValue) {
        this.localPostDate = this.postDate;
      }
    },
  },
  methods: {
    confirmed() {
      this.$emit("confirmed", this.localPostDate);
      this.showConfirmationDialogue = false;
    },
    reject() {
      this.$emit("rejected");

      this.localPostDate = null;

      this.showConfirmationDialogue = false;
    },
  },
};
</script>

<style scoped>
.dialog-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.v-card {
  background: linear-gradient(135deg, white, white);
  color: white;
  border-radius: 16px;
}

.v-btn {
  text-transform: none;
  font-weight: bold;
  padding: 10px 20px;
}

.v-btn:hover {
  opacity: 0.9;
}

.text-h6 {
  color: #ffffff;
}
</style>
