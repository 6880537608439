<template>
  <div class="d-flex align-center">
    <!-- Progress Bar -->
    <span class="truck-body ma-2">
      <div
        class="fill text-center"
        :style="{
          width: params.progress + '%',
          'background-color': getBackgroundColor(params.progress),
        }"
      ></div>
    </span>

    <span class="text-caption text-secondary font-weight-bold">
      {{ params.progress }} %
    </span>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    // Method to determine the background color of the progress bar
    getBackgroundColor(progress) {
      if (progress <= 30) return "#FF5252"; // Red for low progress
      if (progress <= 70) return "#1976D2"; // Blue for moderate progress
      return "#4CAF50"; // Green for high progress
    },
  },
};
</script>

<style lang="scss" scoped>
.truck-body {
  width: 100%;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-left: 10px;
  overflow: hidden;
}

.fill {
  height: 100%;
  background-color: #76c7c0;
  transition: width 0.3s ease-in-out;
}
</style>
