<template>
  <v-row class="justify-center align-center Login-Section">
    <v-col cols="12" sm="8" md="6" lg="4">
      <v-form ref="signUpForm" v-model="isValid">
        <v-card elevation="10" class="pa-2">
          <v-card-title class="d-flex justify-center">
            <img
              class="Brand-Logo mb-10"
              alt="Brand Logo"
              src="@/assets/images/brand-logo.png"
            />
          </v-card-title>

          <v-card-text>
            <v-row class="justify-center">
              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Company Name is Required!']"
                  label="Company Name *"
                  required
                  v-model="companyDetails.company_name"
                />
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Contact Person is Required!']"
                  label="Contact Person *"
                  required
                  v-model="companyDetails.contact_person"
                />
              </v-col>

              <v-col cols="12">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Designation is Required!']"
                  label="Designation *"
                  required
                  v-model="companyDetails.designation"
                />
              </v-col>

              <v-col cols="12" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  :items="compnayTypeList"
                  :rules="[(val) => !!val || 'Company Type is Required!']"
                  label="Company Type *"
                  itemText="user_type"
                  itemValue="value"
                  required
                  v-model="companyDetails.company_type"
                />
              </v-col>

              <v-col cols="12" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[
                    (val) => !!val || 'Email is Required!',
                    (val) => /.+@.+\..+/.test(val) || 'Email must be valid',
                  ]"
                  label="Email *"
                  required
                  v-model="companyDetails.company_email"
                />
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="4" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  :items="allPhoneCodeList"
                  :rules="[(val) => !!val || 'Phone Code is Required!']"
                  label="Phone Code *"
                  itemText="phone_code"
                  itemValue="phone_code"
                  required
                  v-model="companyDetails.phone_code"
                />
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="8" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="number"
                  :rules="[(val) => !!val || 'Contact Number is Required!']"
                  label="Contact Number *"
                  required
                  v-model="companyDetails.company_contact_number"
                />
              </v-col>
              <v-col cols="12" class="pa-2">
                <InputField
                  hide-details="auto"
                  type="text"
                  :rules="[(val) => !!val || 'Address Line 1 is Required!']"
                  label="Address Line 1 *"
                  required
                  v-model="companyDetails.address_line_1"
                />
              </v-col>
              <v-col cols="12" class="pa-2">
                <MultiSelect
                  hide-details="auto"
                  :items="serviceTypeList"
                  :rules="[(val) => !!val || 'Service Type is Required!']"
                  label="Service Type *"
                  itemText="display_name"
                  itemValue="value"
                  required
                  v-model="companyDetails.service_type"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  :disabled="!isValid"
                  @click="submitForm"
                  large
                >
                  Get OTP
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  @click="goToLogin"
                  type="button"
                  text
                  color="primary"
                  depressed
                  large
                >
                  Go To Login
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { encryptText } from "@/utils/functions";
import { serviceTypeListCategory, statusCategory } from "@/utils/choices";
export default {
  components: { InputField, MultiSelect },
  layout: "sigup",
  data() {
    return {
      formErrors: {},
      companyDetails: {},
      allPhoneCodeList: [],
      compnayTypeList: ["Customer", "Transporter"],
      btnLoading: false,
      password: "Password",
      serviceTypeList: serviceTypeListCategory,
      statusCategoryList: statusCategory,
      passwordEye: false,
      isValid: true,
      timer: 30,
      interval: null,
      disableOtpBtn: false,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push("/app/login");
    },

    reset() {
      this.companyDetails = {};
    },
    getPhoneCodeList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        limit: "all",
      };
      this.$api.unauthorisedApi
        .getCountryList(params)
        .then((res) => {
          this.allPhoneCodeList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    async submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        data: this.companyDetails,
      };
      await this.$api.unauthorisedApi
        .addCompanyRequest(payload.data)
        .then((res) => {
          
          this.$bus.$emit("showToastMessage", {
            message:
            "Check Your Mail You will get OTP  Request for verification",
            color: "success",
          });
          this.$router.push("/app/verification-otp");
          this.$bus.$emit("showLoader", false);

        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Update!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
  },
  beforeMount() {
    this.getPhoneCodeList();
  },
};
</script>

<style scoped>
.Login-Section {
  height: 100vh;
}

.Login-Section .Brand-Logo {
  width: 250px;
}
</style>
