export const version = "/api/v1/";
export const version2 = "/api/v2/";

export const company = {
  companyBase: `${version2}company/`,
  companyDocumentDeleteBase: `${version2}company_document/`,
};

export const country = {
  countryBase: `${version2}country/`,
  unauthoriseCountryBase: `${version2}country_list/`,
};

export const currency = {
  currencyBase: `${version2}currency/`,
};

//HSM
export const bidHSM = {
  bidHSMBase: `${version2}bid_hsm/`,
  bidSubHireHSBase: `${version2}bid_subhire_hsm/`,
};

export const unClass = {
  unClassBase: `${version2}un_class/`,
};

export const imoClass = {
  imoClassBase: `${version2}imo_class/`,
};
export const adrClass = {
  adrClassBase: `${version2}adr_class/`,
};
export const bankDetails = {
  bankDetailsBase: `${version2}bank_detail/`,
};
export const city = {
  cityBase: `${version2}city/`,
};

export const role = {
  roleBase: `${version2}role/`,
};

export const branch = {
  branchBase: `${version2}branch/`,
};
export const depot = {
  depotBase: `${version2}depot/`,
};

export const user = {
  userBase: `${version2}gt_user/`,
  newUserBase: `${version2}new_user/`,
  companyRequestBase: `${version2}company_request/`,
  changePasswordBase: `${version2}change_password/`,
  userPreferencesBase: `${version2}user_preferences/`,
};

export const vehicle = {
  vehicleBase: `${version2}vehicle/`,
  vehicleDocBase: `${version2}vehicle_document/`,
};


export const vehicleCost = {
  vehicleCostBase: `${version2}vehicle_cost/`,
 
};

export const vehicleType = {
  vehicleTypeBase: `${version2}vehicle_type/`,
};

export const trailer = {
  trailerBase: `${version2}trailer/`,
  trailerDocBase: `${version2}trailer_document/`,
};

export const lane = {
  laneBase: `${version2}lane/`,
  laneRoute: `${version2}get_lane_route/`,
};

export const location = {
  locationBase: `${version2}location/`,
};

export const bmr = {
  bmrBase: `${version2}bmr/`,
  bmrQuotation: `${version2}bmr/get_quotation/`,
  bmrRoute: `${version2}get_route/`,
};

export const enquiry = {
  rateEnquiry: `${version2}customer_rate_enquiry/`,
};

export const customerContract = {
  customerContractBase: `${version2}customer_contract/`,
  customerContractChargeBase: `${version2}customer_contract_charge/`,
};

export const bidding = {
  bidBase: `${version2}bidding/`,
  transporterBidBase: `${version2}transporter_bidding/`,
  hsmtransporterBidRate: `${version2}bid_hsm/`,
};

export const lrfq = {
  lrfqBase: `${version2}admin_lrfq/`,
  lrfqRequestBase: `${version2}admin_lrfq_request/`,
  transporterLrfqBase: `${version2}transporter_lrfq/`,
};

export const customerInvoice = {
  customerInvoiceBase: `${version2}customer_invoice/`,
  creditNoteBase: `${version2}credit_note/`,
  creditNoteChargeBase: `${version2}credit_note_invoice_charge/`,
  singleChargeBase: `${version2}delivery_advice_invoice_charge/`,
  bulkInvoiceLogsBase: `${version2}bulk_customer_invoice_logs/`,
};

export const transporterInvoice = {
  transporterInvoiceBase: `${version2}transporter_invoice/`,
  legListForInvoice: `${version2}leg_list_for_invoice/`,
  transporterInvoiceDoc: `${version2}transporter_invoice_document/`,
  legDetailsForInvoice: `${version2}invoice_leg/`,
};

export const companyFuelSurcharge = {
  companyFuelSurchargeBase: `${version2}company_fuel_surcharge/`,
};
export const customerAddress = {
  customerAddressBase: `${version2}customer_address/`,
};

export const assetType = {
  assetTypeBase: `${version2}asset_type/`,
};

export const trailerType = {
  trailerTypeBase: `${version2}trailer_type/`,
};

export const containerType = {
  containerTypeBase: `${version2}container_type/`,
};

export const containerCategory = {
  containerCategoryBase: `${version2}container_category/`,
};

export const driver = {
  driverBase: `${version2}driver/`,
  driverDocBase: `${version2}driver_document/`,
  driverAppBase: `${version}driver_app/`,
};

export const transporterContract = {
  transporterContractBase: `${version2}transporter_contract/`,
};

export const phoneCode = {
  phoneCodeBase: `${version2}country_phone_code/`,
};

export const customerOrder = {
  customerOrderBase: `${version2}order/`,
  customerOrderSubscriber: `${version2}order_subscriber/`,
};

export const deliveryAdvice = {
  deliveryAdviceBase: `${version2}da/`,
  deliveryAdviceDocBase: `${version2}delivery_advice_document/`,
  deliveryAdviceMilestone: `${version2}delivery_advice_milestone/`,
  leg: `${version2}leg/`,
  completeLeg: `${version2}leg/complete_sub_hire_leg/`,
};

export const subHire = {
  subHireBase: `${version2}sub_hire/`,
  subHireLegList: `${version2}sub_hire_legs/`,
  transporterSubHireBase: `${version2}transporter_sub_hire/`,
};

export const leaseDriverBonusExcel = {
  leaseDriverBonusExcelBase: `${version2}lease_driver_bonus_excel/`,
};

export const sapBonusPayout = {
  sapBonusPayoutBase: `${version2}sap_driver_processing/`,
  sapDriverBonusPayoutBase: `${version2}sap_driver_bonus_payout/`,
};
export const leaseDriver = {
  leaseDriverBase: `${version2}lease_driver/`,
};

export const daHazardousCargo = {
  daHazardousCargoBase: `${version2}da_hazardous_cargo/`,
};
export const deliveryAdviceCharges = {
  deliveryAdviceChargesBase: `${version2}delivery_advice_charge/`,
};

export const legCharges = {
  legChargesBase: `${version2}leg_charge/`,
};
export const tripMilestones = {
  tripMilestonesBase: `${version2}trip_milestone/`,
};

export const charge = {
  chargeBase: `${version2}charge/`,
};

export const tag = {
  tagBase: `${version2}tag/`,
};

export const checkList = {
  checkListBase: `${version2}check_list/`,
};

export const termsCondtionList = {
  termsCondtionListBase: `${version2}terms_and_condition/`,
};

export const driverLeave = {
  driverLeaveBase: `${version2}driver_leave/`,
};

export const maintenance = {
  maintenanceBase: `${version2}asset_maintenance/`,
};

export const incidentLog = {
    incidentLogBase: `${version2}incident_logs/`,
};

export const trip = {
  tripBase: `${version2}trip/`,
  legList: `${version2}leg_list/`,
  legBase: `${version2}leg/`,
  legContainerList: `${version2}leg_container_list/`,
  legPODBase: `${version2}leg_pod/`,
  tripLogs: `${version2}trip_logs/`,
};

export const reports = {
  reportBase: `${version2}report/`,
};
export const reportCenter = {
  reportCenterBase: `${version2}report_center/`,
};

export const report = {
  reportBase: `${version2}report/`,
};

export const department = {
  departmentBase: `${version2}department/`,
};

export const quotation = {
  quotationBase: `${version2}quotation/`,
};

export const integration = {
  integrationBase: `${version2}integration/`,
};

export const milestone = {
  milestoneBase: `${version2}milestone/`,
};

export const nationality = {
  nationalityBase: `${version2}nationality/`,
};

export const demoExcelSheetDownload = {
  bulkExcelBase: `${version2}get_excel_sample/`,
  uploadCustomerContract: `${version2}bulk_customer_contract/`,
  downloadCustomerContract: `${version2}bulk_download_apiview/`,
};

export const chat = {
  chatAttachment: `${version2}chat_attachment/`,
};

export const emailSubscriber = {
  emailSubscriberBase: `${version2}email_subscriber/`,
};

export const activityLogs = {
  logsUrl: version2,
};

export const ticket = {
  ticketBase: `${version2}ticket/`,
};

export const paymentReceipt = {
  base: `${version2}payment_receipt/`,
};

export const dashboard = {
  adminDashboardBase: `${version2}admin/dashboard/`,
  transporterDashboardBase: `${version2}transporter/dashboard/`,
  customerDashboardBase: `${version2}customer/dashboard/`,
};
