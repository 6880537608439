import Blank from "@/layouts/blank.vue"

import DriverRoutes from "@/router/admin/assetManagement/driver"
import TrailerRoutes from "@/router/admin/assetManagement/trailer"
import VehicleRoutes from "@/router/admin/assetManagement/vehicle"
import VehicleCostRoutes from "@/router/admin/assetManagement/vehicleCost"

const assetManagementRoutes = [
  {
    path: "asset-management",
    name: "app-admin-asset-management",
    component: Blank,
    children: [
      ...DriverRoutes,
      ...TrailerRoutes,
      ...VehicleCostRoutes,
      ...VehicleRoutes,
    ]
  },
]

export default assetManagementRoutes