import Blank from "@/layouts/blank.vue";

import CustomerRoutes from "@/router/admin/companyManagement/customer";
import TransporterRoutes from "@/router/admin/companyManagement/transporter";
import CompanyRequestListRoutes from "@/router/admin/companyManagement/newCompanyRequest";

const companyManagementRoutes = [
  {
    path: "company-management",
    name: "app-admin-company-management",
    component: Blank,
    children: [
      ...CustomerRoutes,
      ...TransporterRoutes,
      ...CompanyRequestListRoutes,
    ],
  },
];

export default companyManagementRoutes;
