<template>
  <v-dialog width="1000" v-model="showApproveData">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> View SAP Approved List </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="showApproveData = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <AgGridVue
          style="width: 100%; height: calc(90vh - 172px)"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="headers"
          :context="context"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :grid-options="gridOptions"
          :suppressDragLeaveHidesColumns="true"
          :rowData="sapDriverPayoutDataList"
          rowSelection="multiple"
        ></AgGridVue>
        <v-col
          v-if="totalItems > 0"
          cols="12"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
export default {
  components: {
    InputField,
    MultiSelect,
    AgGridVue,
    Pagination,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String],
      required: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getSAPDriverPayoutsDataList();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showApproveData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  data() {
    return {
      isValid: true,
      isTextInput: false,
      formErrors: {},
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      headers: [
        {
          headerName: "Driver Name",
          field: "employee_name",
          sortable: true,
          //   headerCheckboxSelection: true,
          //   checkboxSelection: (params) => {
          //     return params.data.status !== "Approved";
          //   },
        },

        {
          headerName: "Total Bonus",
          field: "total_bonus",
          sortable: true,
        },
        {
          headerName: "Personnel No.",
          field: "personnel_no",
          sortable: true,
        },
        {
          headerName: "Wage type.",
          field: "wage_type",
          sortable: true,
        },
        {
          headerName: "Currency",
          field: "currency",
          sortable: true,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
        },
      ],
      sapDriverPayoutDataList: [],
      gridApi: null,
      gridColumnApi: null,
      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  methods: {
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSAPDriverPayoutsDataList();
    },
    prevPage() {
      this.pageNo--;
      this.getSAPDriverPayoutsDataList();
    },
    nextPage() {
      this.pageNo++;
      this.getSAPDriverPayoutsDataList();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    getSAPDriverPayoutsDataList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        driver_bonus_processing: this.ID,
        status: "Approved",
        ...params,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.sapBonusPayout
        .getSAPDriverPayoutDataList(params)
        .then((res) => {
          this.sapDriverPayoutDataList = res.data;

          this.totalItems = res.count;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
