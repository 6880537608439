<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          color="primary"
          :name="`vehicle-list-edit-${params?.rowIndex}`"
          class="ml-1 mr-2"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
          >mdi-pencil</v-icon
        >
      </template>
      <span>Edit</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editIncidentVehicle(
        this.params.data.id
      );
    },
  },
};
</script>
