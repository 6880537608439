<template>
  <FormComponent
    :title="`Edit Credit Note - ${invoiceCreditNoteDetails.credit_no}`"
    :backRoute="backRoute"
  >
    <template #formContent>
      <v-row class="ma-2">
        <v-col cols="3" class="Form-Columns-Dense">
          <DateField
            :inputProps="{
              dense: true,
              disabled: isReadOnly,
              label: 'Credit Note Date *',
              'hide-details': 'auto',
              rules: [(val) => !!val || 'Credit Note Date is Required!'],
            }"
            v-model="invoiceCreditNoteDetails.credit_note_date"
          />
        </v-col>
        <v-col cols="3" class="Form-Columns-Dense">
          <InputField
            hide-details="auto"
            type="text"
            label="Remarks"
            :readonly="isReadOnly"
            :clearable="!isReadOnly"
            v-model="invoiceCreditNoteDetails.remarks"
          />
        </v-col>
        <v-col cols="3" class="Form-Columns-Dense d-flex align-center">
          <v-btn
            depressed
            :disabled="isReadOnly"
            color="primary"
            @click="partiallyUpdateCreditNote()"
          >
            Update
          </v-btn>
        </v-col>

        <v-col cols="3" class="Form-Columns-Dense">
          <InputField
            hide-details="auto"
            type="number"
            :label="`Total Amount (${$globals.currency})`"
            readonly
            :clearable="false"
            v-model="invoiceCreditNoteDetails.total_credit_amount"
          />
        </v-col>

        <v-col cols="3" class="Form-Columns-Dense">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            @click:clear="onEnter"
            hide-details="auto"
            v-model="filters.search"
            v-on:keyup.enter="onEnter"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="py-2" v-if="invoiceAdditionalChargeList.length">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 357px)"
            class="small-table ag-theme-alpine"
            id="legsGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="invoiceAdditionalChargeList"
          ></AgGridVue>
          <v-col cols="12" class="py-0 d-flex justify-end"> </v-col>
        </v-col>
        <v-col cols="12" class="Form-Columns-Dense text-right">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalInvoiceAdditionalCharge"
            :pageSize="itemsPerPage"
            :itemsPerPageValues="setItemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>
      <CustomerInvoiceCreditChargeFormDialog
        v-model="showInvoiceAdditionalChargeForm"
        :isEditing="isEditing"
        @refreshList="refreshData"
        :isReadOnly="isReadOnly"
        :selectedChargeData="chargeData"
        @update:selectedChargeData="updateSelectedChargeData"
      />
    </template>
  </FormComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import editCreditNoteButton from "@/components/AgGridComponents/editCreditNoteButton.vue";
import CustomerInvoiceCreditChargeFormDialog from "@/components/ModuleBased/dialogs/CustomerInvoiceCreditChargeFormDialog.vue";

export default {
  components: {
    ListComponent,
    AgGridVue,
    InputField,
    MultiSelect,
    editCreditNoteButton,
    DateField,
    Pagination,
    CustomerInvoiceCreditChargeFormDialog,
    FormComponent,
  },
  data() {
    return {
      backRoute: { path: "/app/admin/invoice/credit-note/list" },
    
      invoiceCreditNoteDetails: {},
      invoiceAdditionalChargeList: [],
      setItemsPerPage: [10, 15, 20, 50, 100, 500],

      showInvoiceAdditionalChargeForm: false,
      showAddDAToInvoiceForm: false,
      isEditing: false,
      selectedDaId: null,
      selectedDaIndex: null,
      selectedChargeIndex: null,
      selectedChargeData: {},
      chargeData: {},
      allChargeList: [],
      customerInvoiceDas: [],
      headers: [
        {
          headerName: "DA No",
          field: "da_no",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Full Amount",
          field: "full_amount_credit",
          editable: true,
          minWidth: 20,
          cellEditor: "agSelectCellEditor", // Use the built-in select cell editor
          cellEditorParams: {
            values: ["Yes", "No"], // Specify the allowed values
          },
          valueGetter: function (params) {
            return params.data.full_amount_credit !== null &&
              params.data.full_amount_credit !== undefined
              ? params.data.full_amount_credit
              : "No";
          },
          valueSetter: function (params) {
            params.data.full_amount_credit = params.newValue;
            return true;
          },
          cellStyle: (params) => {
            if (params.data && params.data.charge_type !== "Other") {
              return { color: "#f07025" };
            }
          },
        },

        {
          headerName: "Charge",
          field: "charge_title",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },

        {
          headerName: "Description",
          field: "description",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Revenue Gl No",
          field: "gl_no",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "TAX(%)",
          field: "tax_percentage",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `TAX Amount (${this.$globals.currency})`,
          field: "tax_amount",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Amount With Tax (${this.$globals.currency})`,
          field: "amount_with_tax",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: `Credit Amount (${this.$globals.currency})`,
          field: "charge_credit_amount",
          cellStyle: (params) => {
            if (params.data && params.data.charge_type != "Other") {
              return { color: "#f07025" };
            }
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 100,
          cellRendererFramework: "editCreditNoteButton",
        },
      ],
      chargeId: null,
      invoiceChargesTimeout: null,
      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalInvoiceAdditionalCharge: 100,
      containerCentric: "No",
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isReadOnly() {
      return (
        this.invoiceCreditNoteDetails &&
        this.invoiceCreditNoteDetails.status == "Accepted"
      );
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    updateSelectedChargeData(updatedItem) {
      this.$bus.$emit("showLoader", true);

      let payload = {
        ...updatedItem,
      };
      //payload.charge = this.newchargeId;
      

      this.$api.customerInvoice
        .updateSingleCreditCharge(payload.id, payload)
        .then((res) => {
          if (res && res.data) {
            this.$emit("refreshList", res.data);
          }
          this.customerInvoiceFormDialog = false;
          this.refreshData();
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          if (err && err.data) {
            this.formErrors = { ...err.data };
          }
          this.$bus.$emit("showLoader", false);
        });
    },
    onEnter() {
      this.pageNo = 1;
      this.getCreditNoteInvoiceCharges(this.$route.params.id);
    },
    addAdditionalCharges(data) {
      this.selectedChargeData = {};
      this.isEditing = false;
      this.selectedDaId = data.delivery_advice;
      this.showInvoiceAdditionalChargeForm = true;
    },
    updateAdditionalCharges(data) {
      this.chargeData = data;
      //this.chargeId = id;
      this.isEditing = true;
      this.showInvoiceAdditionalChargeForm = true;
    },
    deleteAdditionalCharges(id) {
      if (confirm("Are you sure you want to delete this charge?")) {
        this.$bus.$emit("showLoader", true);

        this.$api.customerInvoice
          .deleteSingleCharge(id)
          .then((res) => {
            this.getInvoiceObject(this.$route.params.id);
            this.getCreditNoteInvoiceCharges(this.$route.params.id);
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    removeDA(daId) {
      if (
        confirm("Are you sure you want to remove this DA/Charge from the Credit Note?")
      ) {
        this.$bus.$emit("showLoader", true);
        this.$api.customerInvoice
          .removeChargesFromCreditNote(daId)
          .then((res) => {
            this.getCreditNoteObject(this.$route.params.id);
            this.getCreditNoteInvoiceCharges(this.$route.params.id);
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    refreshData() {
      this.getCreditNoteObject(this.$route.params.id);
      this.getCreditNoteInvoiceCharges(this.$route.params.id);

      this.isEditing = false;
      this.selectedDaId = null;
      this.selectedChargeData = {};
    },

    partiallyUpdateCreditNote() {
      this.$bus.$emit("showLoader", true);
      const payload = {
        credit_note_date: this.invoiceCreditNoteDetails.credit_note_date,
        remarks: this.invoiceCreditNoteDetails.remarks,
      };
      this.$api.customerInvoice
        .partiallyUpdateCreditNote(
          this.invoiceCreditNoteDetails.id,
          payload
        )
        .then((res) => {
          this.$bus.$emit("showToastMessage", {
            message: "Credit Note Updated",
            color: "success",
          });
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },
    getCreditNoteObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerInvoice
        .getCreditNoteObject(id)
        .then((res) => {
          this.invoiceCreditNoteDetails = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },
    getCreditNoteInvoiceCharges(id, params = {}) {
      if (this.invoiceChargesTimeout) {
        clearTimeout(this.invoiceChargesTimeout);
      }
      this.invoiceChargesTimeout = setTimeout(() => {
        params = {
          ...params,
          ...this.filters,
          offset: this.offset,
          limit: this.itemsPerPage,
          credit_note: id,
        };
        this.$bus.$emit("showLoader", true);
        this.$api.customerInvoice
          .getCreditNoteInvoiceAdditionalChargeList(params)
          .then((res) => {
            this.invoiceAdditionalChargeList = res.data;
            this.totalInvoiceAdditionalCharge = res.count;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
          });
      }, 100);
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getCreditNoteInvoiceCharges(this.$route.params.id);
    },
    prevPage() {
      this.pageNo--;
      this.getCreditNoteInvoiceCharges(this.$route.params.id);
    },
    nextPage() {
      this.pageNo++;
      this.getCreditNoteInvoiceCharges(this.$route.params.id);
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },

  mounted() {
    if (
      "id" in this.$route.params &&
      typeof parseInt(this.$route.params.id) == typeof 1
    ) {
      // this.getChargeList();
      this.getCreditNoteInvoiceCharges(this.$route.params.id);
      this.getCreditNoteObject(this.$route.params.id);
    }
    this.setBackRoute();
  },
};
</script>
