import { country, user } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (unauthoriseAxios) => ({
  getCountryList(params = {}) {
    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .get(country.unauthoriseCountryBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getNewUserList(params = {}) {
    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .get(user.newUserBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getNewUserObject(id) {
    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .get(`${user.newUserBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addUser(data) {
    console.log(data, "data");
    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .post(user.newUserBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addCompanyRequest(payload) {

    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .post(user.companyRequestBase, payload)
        .then((res) => {
        
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateUser(payload) {
    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .patch(`${user.newUserBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  otpVerification(payload = {}) {
    console.log(`${user.companyRequestBase}mark_otp_verification/`);
    return new Promise((resolve, reject) => {
      unauthoriseAxios
        .post(`${user.companyRequestBase}mark_otp_verification/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

});
