import Dashboard from "@/pages/admin/Index.vue";

import AssetManagementRoutes from "@/router/admin/assetManagement/index";
import ConfigurationRoutes from "@/router/admin/configuration";
import UserManagementRoutes from "@/router/admin/userManagement";
import CompanyManagementRoutes from "@/router/admin/companyManagement";
import roleManagementRoutes from "@/router/admin/roleManagement";
import quotationRoutes from "@/router/admin/quotation";
import customerContractRoutes from "@/router/admin/customerContract";
import transporterContractRoutes from "./transporterContract";
import transporterRFQRoutes from "./transporterRFQ";
import biddingRoutes from "./commercialManagement/bidding";
import laneManagementRoutes from "@/router/admin/laneManagement";
import deliveryAdviceRoutes from "@/router/admin/deliveryAdvice";
import integrationRoutes from "@/router/admin/integration";
import integrationLogRoutes from "@/router/admin/integrationLogs";
import reportRoutes from "@/router/admin/report";
import reportCenterRoutes from "@/router/admin/reportCenter";
import orderRoutes from "@/router/admin/order";
import invoiceRoutes from "@/router/admin/invoice";
import tripRoutes from "@/router/admin/trip";
import ticketRoutes from "@/router/admin/ticket";
import subHireRoutes from "@/router/admin/subHire";
import leaseDriverRoutes from "@/router/admin/leaseDriver";
import SapBonusPayoutRoutes from "@/router/admin/sapBonusPayout";

const adminRoutes = [
  {
    path: "/",
    redirect: "/app/admin/dashboard",
  },
  {
    path: "dashboard",
    name: "app-admin-dashboard",
    component: Dashboard,
  },
  ...AssetManagementRoutes,
  ...ConfigurationRoutes,
  ...reportCenterRoutes,
  ...UserManagementRoutes,
  ...CompanyManagementRoutes,
  ...roleManagementRoutes,
  ...SapBonusPayoutRoutes,
  ...quotationRoutes,
  ...customerContractRoutes,
  ...transporterContractRoutes,
  ...transporterRFQRoutes,
  ...biddingRoutes,
  ...laneManagementRoutes,
  ...deliveryAdviceRoutes,
  ...integrationRoutes,
  ...integrationLogRoutes,
  ...reportRoutes,
  ...orderRoutes,
  ...invoiceRoutes,
  ...leaseDriverRoutes,
  ...tripRoutes,
  ...ticketRoutes,
  ...subHireRoutes,
];

export default adminRoutes;
