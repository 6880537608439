<template>
  <div>
    <v-chip
      label
      x-small
      :color="getColorWithLighten(params.status)"
      :text-color="getColorWithDarken(params.status)"
      class="font-weight-bold px-2"
    >
      {{ params.status }}
    </v-chip>
  </div>
</template>

<script>
export default {
  methods: {
    getColorWithLighten(status) {
      // Check for specific statuses and assign corresponding colors
      if (status === "success") {
        return "green lighten-4"; // Green for success
      } else if (status === "inprogress") {
        return "yellow lighten-4"; // Yellow for in progress
      } else if (status === "Pending") {
        return "yellow lighten-2"; // Yellow for in progress
      } else if (status === "Verified") {
        return "success"; // Yellow for in progress
      }

      // Fallback for other statuses, dynamic colors can be handled here
      const colorKey = this.$globals.getColorKey(status);
      if (colorKey) {
        return `${colorKey.replace("--text", "")} lighten-4`;
      }

      // Default color if no match
      return "black lighten-4";
    },

    getColorWithDarken(status) {
      // Check for specific statuses and assign corresponding darkened colors
      if (status === "success") {
        return "green darken-4"; // Darkened green for success
      } else if (status === "inprogress") {
        return "yellow darken-4"; // Darkened yellow for in progress
      } else if (status === "pending") {
        return "blue lighten-4"; // Yellow for in progress
      } else if (status === "verified") {
        return "green"; // Yellow for in progress
      }

      // Fallback for other statuses, dynamic colors can be handled here
      const colorKey = this.$globals.getColorKey(status);
      if (colorKey) {
        return `${colorKey.replace("--text", "")} darken-4`;
      }

      // Default dark color if no match
      return "black darken-4";
    },
  },
};
</script>

<style scoped>
/* You can add custom styles if needed */
</style>
