import { reportCenter } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getReportList(params = {}, headers = {}, responseType = "json") {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reportCenter.reportCenterBase}`, {
          params: params,
          responseType: responseType,
          headers: headers,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportData(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${reportCenter.reportCenterBase}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportBlobObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reportCenter.reportCenterBase}${id}/download/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportPdfBlobObject(id, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reportCenter.reportCenterBase}${id}/download_report_pdf`, {
          params: params,
          responseType: "blob",
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${reportCenter.reportCenterBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getReportOptions(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .options(reportCenter.reportCenterBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
