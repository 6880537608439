<template>
  <div>
    <!-- <v-row class="ma-0">
      <v-col cols="12" lg="6" class="pb-1">
        <span class="text-h5"> {{ title }} </span>
        <v-badge
          v-if="totalCount"
          top
          :content="totalCount"
          overlap
          class="mb-4 ml-2"
        >
        </v-badge>
      </v-col>

      <v-col cols="12" lg="6" class="text-right align-self-center pb-1">
        <slot name="actions" />
      </v-col> -->

    <v-row class="ma-0" justify="space-between">
      <v-col class="pb-1 d-flex align-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-if="['string', 'object'].indexOf(typeof backRoute) > -1"
              @click="callBackRoute"
              v-bind="attrs"
              v-on="on"
              class="mr-3"
              name="back-button"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <span class="text-h5"> {{ title }} </span>
        <v-badge
          v-if="totalCount"
          :content="totalCount"
          overlap
          class="ma-0 ml-4"
        >
        </v-badge>
      </v-col>
   
      <v-col cols="auto" class="text-right align-self-center pb-1">
        <slot name="actions" />
      </v-col>

      <v-col cols="12" class="py-1">
        <slot name="list" />
      </v-col>
    </v-row>

    <div>
      <slot name="dialogs" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backRoute: {
      type: [String, Object],
    },
    title: {
      type: String,
      default: "Title",
    },
    totalCount: {
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    callBackRoute() {
      this.$router.push(this.backRoute);
    },
  },
};
</script>
