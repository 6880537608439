<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          :disabled="params && params.data && params.data.status != 'Verified'"
          class="ml-1 mr-2"
          color="success"
          v-on="on"
          v-bind="attrs"
          @click="acceptCompany"
        >
          mdi-check-bold
        </v-icon>
      </template>
      <span>Accept</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    acceptCompany() {
      this.params.context.parentComponent.acceptCompanyData(this.params.data);
    },
  },
};
</script>

<style></style>
