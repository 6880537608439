import { integration } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCompanyIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}company_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getCompanyIntegrationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}company_logs/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriverIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}driver_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriverIntegrationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}driver_logs/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriverLeaveIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}driver_leave_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getDriverLeaveIntegrationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}driver_leave_logs/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getAssetMaintenanceIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}asset_maintenance_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getAssetMaintenanceIntegrationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}asset_maintenance_logs/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getCustomerInvoiceIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}customer_invoice_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  sentDataToSAP(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.integrationBase}post_to_sap/`,
          data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getPaymentReceiptIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}payment_receipt_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCreditNoteIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}credit_note_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getSubHireIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}sub_hire_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSapIntegrationLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.integrationBase}sap_bonus_integration_logs/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});


