import { vehicleCost } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getVehicleCostBulkList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(vehicleCost.vehicleCostBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteVehicleCostData(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${vehicleCost.vehicleCostBase}${id}/`) 
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
