
export const customerTariffInstruction = [
  "Customer Code",
  "Source",
  "Mid",
  "Destination",
  "Job Type",
  "Container Type",
  "Rate",
  "Start Date",
  "End Date",
  "Admin Remarks",
  "Customer Remarks"
]

export const leaseDriverBonusInstruction = [
  "Employee Name",
  "Emp Id",
  "No Of Trips",
  "Total",
  "Trip Date"
]

export const fuelSurchargeInstruction = [
  "Company Code",
  "Surcharge"
]
export const locationInstruction = [
  "Name",
  "Is Port",
  "Is Free Zone",
  "Location Code"
]

export const driverInstruction = [
  "Driver Name",
  "DOB",
  "Phone Code",
  "Contact Number",
  "Username",
  "Email Id",
  "Designation",
  "Service Start Date",
  "Service End Date",
  "Assigned Vehicle",
  "Nationality",
  "national_id",
  "National Id Expiry",
  "Passport Number",
  "Passport Expiry",
  "Visa Number",
  "Visa Expiry",
  "License Type",
  "License Number",
  "License Expiry",
  "Imco License Number",
  "Imco License Expiry"
]


export const vehicleInstruction = [
  "Vehicle Type",
  "Vehicle Id",
  "Vehicle Number",
  "Inventory Number",
  "Location",
  "Imco License No",
  "Imco License Expiry",
  "Reg Start Date",
  "Reg Exp Date",
  "GPS Id",
  "Odometer Reading",
  "Payload Capacity",
  "Length",
  "Width",
  "Height",
  "Temperature Min",
  "Temperature Max"
]


export const vehicleCostInstruction = [
  "Vehicles",
  "Date",
  "Pk",
  "Gallons",
  "Kms",
  "Cost",
  "Remarks",
 
]
export const trailerInstruction = [
  "Trailer Type",
  "Trailer Id",
  "Trailer Number",
  "Inventory Number",
  "Location",
  "Road Trail",
  "Imco License No",
  "Imco License Expiry",
  "Reg Start Date",
  "Reg Exp Date",
  "Payload Capacity",
  "Length",
  "Width",
  "Height"
]

export const companyInstruction = [
  "Company Code",
  "Company Name",
  "Rating",
  "Address Line 1",
  "Address Line 2",
  "Country",
  "City",
  "Company Contact Number",
  "Company Email",
  "Payment Terms",
  "Currency",
  "VAT Applicability",
  "VAT Registration Number",
  "Service Type",
  "Customer Group",
  "Remarks"
]

export const transporterCompanyInstruction = [
  "Company Code",
  "Company Name",
  "Rating",
  "Address Line 1",
  "Address Line 2",
  "Country",
  "City",
  "Company Contact Number",
  "Company Email",
  "Payment Terms",
  "Currency",
  "VAT Applicability",
  "VAT Registration Number",
  "Service Type",
  "Remarks"
]
