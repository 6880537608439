<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          :disabled="params && params.data.total_records === 0"
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          :disabled="params && params.data.total_records === 0"
          v-bind="attrs"
          @click="showApprove"
        >
          mdi-bookmark-check
        </v-icon>
      </template>
      <span>SAP Approved</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewPayout(this.params.data.id);
    },
    showApprove() {
      this.params.context.parentComponent.showApproveData(this.params.data.id);
    },
  },
};
</script>
