import ReportCenterList from "@/pages/admin/report_center/ReportCenterList.vue";

const reportCenterRoutes = [
  {
    path: "report_center",
    name: "app-admin-report-center",
    component: ReportCenterList,
  },
]

export default reportCenterRoutes