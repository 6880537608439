<template>
  <v-dialog v-model="showSubhireDialog" fullscreen>
    <v-card>
      <v-card-title class="secondary white--text px-4 py-2">
        <div v-if="!subHireID">
          <span v-if="!subHireID"> Sub Hire</span>
        </div>
        <div v-else>
          <span> Sub Hire {{ subHireNo }}</span>
        </div>

        <v-spacer></v-spacer>
        <v-btn icon @click="resetForm(), (showSubhireDialog = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        v-if="formErrors && formErrors.non_field_errors"
        class="mt-2"
      >
        <v-alert close-icon="mdi-close" dense type="error">
          <v-list
            class="pa-0"
            dense
            style="background: inherit !important"
            v-for="(error, i) in formErrors.non_field_errors"
            :key="i"
          >
            <v-list-item dense style="min-height: 20px !important">
              <span>{{ i + 1 }} .</span><span>{{ error }}</span>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-card-text>

      <v-card-text class="pa-2">
        <v-form ref="subhireForm" v-if="value" v-model="isValid">
          <v-row class="ma-0">
            <v-col cols="12" lg="7">
              <v-row>
                <v-col cols="12" lg="auto">
                  <span class="text-caption">Route</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ daLaneTitle }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">Customer</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ daCustomer }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col
                  cols="12"
                  lg="auto"
                  v-if="subHireFormDetails && subHireFormDetails.da_rate"
                >
                  <span class="text-caption">Revenue</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ subhireRate }} {{ $globals.currency }}
                  </p>
                </v-col>
                <v-col cols="12" lg="auto" v-else>
                  <span class="text-caption">Revenue</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ daRate }} {{ $globals.currency }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">Additional Charge</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ customerDaAdditionalCharge }} {{ $globals.currency }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">Total Revenue</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ totalRevenue }} {{ $globals.currency }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">Taxable Amount</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ daTotalTaxableAmount }} {{ $globals.currency }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">Total Revenue With Tax</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ grandTotalProperty }} {{ $globals.currency }}
                  </p>
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">Sub Hire Leg Count</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ totalDa }}
                  </p>
                </v-col>

                <v-col cols="12" lg="auto">
                  <span class="text-caption">DA Container Type</span>
                  <p class="text-subtitle-1 font-weight-medium mb-0">
                    {{ daContainerType }}
                  </p>
                </v-col>

                <v-col cols="12" class="px-0">
                  <v-divider></v-divider>
                </v-col>

                <v-row class="ma-0 d-flex align-center">
                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <v-switch
                      :readonly="
                        subHireFormDetails.bid_request != null ||
                        subHireFormDetails.lrfq_request != null
                      "
                      class="pa-0 ma-0"
                      :label="`From Bid : ${
                        jobDetails.is_bid == 'Yes' ? 'Yes' : 'No'
                      }`"
                      true-value="Yes"
                      false-value="No"
                      hide-details="auto"
                      v-model="jobDetails.is_bid"
                      :error-messages="formErrors.is_bid"
                      @change="
                        delete formErrors.is_bid;
                        getBidList();
                      "
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="subHireFormDetails.bid_request != null"
                  >
                    <span class="text-caption">Bid Id</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ subHireFormDetails.bid_request_id }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="Form-Columns-Dense"
                    v-if="jobDetails.is_bid == 'Yes'"
                  >
                    <MultiSelect
                      label="Select Bid *"
                      :items="bidList"
                      itemText="title"
                      itemValue="id"
                      :returnObject="true"
                      hide-details="auto"
                      :rules="[(val) => !!val || 'Bid is required']"
                      v-model="bidRequest"
                      :error-messages="formErrors.bidRequest"
                      @change="
                        delete formErrors.bidRequest;
                        setTransporterDetail();
                      "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    class="Form-Columns-Dense"
                    v-if="jobDetails.is_bid == 'Yes'"
                  >
                    <v-btn
                      color="primary"
                      depressed
                      :disabled="
                        (subHireFormDetails &&
                          subHireFormDetails.status &&
                          subHireFormDetails.status == 'Invoice Updated') ||
                        subHireFormDetails.status == 'Completed' ||
                        bidList.length > 0 ||
                        (this.bidRequestDetail &&
                          this.bidRequestDetail.da_bid_exist)
                      "
                      @click="createBid"
                    >
                      Create Bid
                    </v-btn>
                  </v-col>
                </v-row>

                <v-col cols="12" class="px-0">
                  <v-divider></v-divider>
                </v-col>

                <v-row class="ma-0 d-flex align-center">
                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <v-switch
                      :readonly="subHireFormDetails.lrfq_request != null"
                      class="pa-0 ma-0"
                      :label="`From Lrfq : ${
                        lrfqDetails.is_lrfq == 'Yes' ? 'Yes' : 'No'
                      }`"
                      true-value="Yes"
                      false-value="No"
                      hide-details="auto"
                      v-model="lrfqDetails.is_lrfq"
                      :error-messages="formErrors.is_lrfq"
                      @change="
                        delete formErrors.is_lrfq;
                        getLrfqList();
                      "
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="auto"
                    v-if="subHireFormDetails.lrfq_request != null"
                  >
                    <span class="text-caption">Lrfq Id</span>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ subHireFormDetails.lrfq_id }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="Form-Columns-Dense"
                    v-if="lrfqDetails.is_lrfq == 'Yes'"
                  >
                    <MultiSelect
                      label="Select Lrfq *"
                      :items="lrfqList"
                      itemText="title"
                      itemValue="id"
                      :returnObject="true"
                      hide-details="auto"
                      :rules="[(val) => !!val || 'Lrfq is required']"
                      v-model="lrfqRequest"
                      :error-messages="formErrors.lrfqRequest"
                      @change="
                        delete formErrors.lrfqRequest;
                        setTransporterDetailFromLrfq();
                      "
                    />
                  </v-col>
                </v-row>

                <v-col cols="12" class="px-0">
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Transporter *"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.status &&
                        subHireFormDetails.status == 'Invoice Updated') ||
                      subHireFormDetails.status == 'Completed' ||
                      jobDetails.is_bid == 'Yes' ||
                      subHireFormDetails.bid_request != null
                    "
                    :items="transporterList"
                    itemText="company_name"
                    itemValue="id"
                    hide-details="auto"
                    :rules="[(val) => !!val || 'Transporter is required']"
                    v-model="subHireFormDetails.transporter"
                    :error-messages="formErrors.transporter"
                    @change="
                      delete formErrors.transporter, checkVatApplicablity()
                    "
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    :label="`Transport Charge (${$globals.currency}) *`"
                    hide-details="auto"
                    :clearable="false"
                    v-model="subHireFormDetails.rate"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.status &&
                        subHireFormDetails.status == 'Invoice Updated') ||
                      subHireFormDetails.status == 'Completed' ||
                      jobDetails.is_bid == 'Yes' ||
                      subHireFormDetails.bid_request != null
                    "
                    :rules="[(val) => !!val || 'Rate is required']"
                    :error-messages="formErrors.rate"
                    @input="
                      delete formErrors.rate;
                      setAdditionalChargeDetails();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    :clearable="false"
                    readonly
                    label="Tax(%) *"
                    class="grey lighten-3"
                    hide-details="auto"
                    v-model="subHireFormDetails.tax_percentage"
                    :error-messages="formErrors.tax_percentage"
                    @input="
                      delete formErrors.tax_percentage;
                      setTotalTaxableAmount();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    readonly
                    :label="`Additional Charge (${$globals.currency})`"
                    class="grey lighten-3"
                    hide-details="auto"
                    :clearable="false"
                    v-model="transportTotalAdditionalCharge"
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    readonly
                    :label="`Total Cost (${$globals.currency})`"
                    class="grey lighten-3"
                    hide-details="auto"
                    :clearable="false"
                    v-model="subHireFormDetails.total_cost"
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    :label="`Total Taxable Amount (${$globals.currency})`"
                    class="grey lighten-3"
                    :clearable="false"
                    readonly
                    hide-details="auto"
                    v-model="subHireFormDetails.total_taxable_amount"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    :label="`Total Cost With Tax (${$globals.currency})`"
                    :clearable="false"
                    class="grey lighten-3"
                    readonly
                    hide-details="auto"
                    v-model="subHireFormDetails.total_cost_with_tax"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    min="0"
                    readonly
                    :label="`Margin Rate (${$globals.currency})`"
                    class="grey lighten-3"
                    hide-details="auto"
                    v-model="marginRate"
                    :clearable="false"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    min="0"
                    readonly
                    :label="`Margin Percentage*`"
                    class="grey lighten-3"
                    :clearable="false"
                    hide-details="auto"
                    v-model="marginPercent"
                  />
                </v-col>

                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Reason For Sub Hire*"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.status &&
                        subHireFormDetails.status == 'Invoice Updated') ||
                      subHireFormDetails.status == 'Completed'
                    "
                    :rules="[(val) => !!val || 'Reason is required']"
                    hide-details="auto"
                    v-model="subHireFormDetails.reason_for_sub_hire"
                    :error-messages="formErrors.reason_for_sub_hire"
                    :clearable="false"
                    @input="delete formErrors.reason_for_sub_hire"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-switch
                    class="mt-1"
                    hide-details="auto"
                    label="Cargo liability insurance"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.status &&
                        subHireFormDetails.status == 'Invoice Updated') ||
                      subHireFormDetails.status == 'Completed'
                    "
                    true-value="Yes"
                    false-value="No"
                    v-model="subHireFormDetails.cargo_liability_insurance"
                    :error-messages="formErrors.cargo_liability_insurance"
                    @change="delete formErrors.cargo_liability_insurance"
                  ></v-switch>
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <v-switch
                    class="mt-1"
                    hide-details="auto"
                    label="General third party insurance"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.status &&
                        subHireFormDetails.status == 'Invoice Updated') ||
                      subHireFormDetails.status == 'Completed'
                    "
                    true-value="Yes"
                    false-value="No"
                    v-model="subHireFormDetails.general_third_party_insurance"
                    :error-messages="formErrors.general_third_party_insurance"
                    @change="delete formErrors.general_third_party_insurance"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" lg="5">
              <AgGridVue
                style="width: 100%; height: 45vh"
                class="ag-theme-alpine"
                rowSelection="multiple"
                id="combineLegGrid"
                :columnDefs="headers"
                :context="context"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :grid-options="gridOptions"
                :rowData="legList"
                @selection-changed="setSelectedRows"
              ></AgGridVue>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="6" class="Form-Columns-Dense">
              <span class="text-h6 font-weight-regular"> Add Charge </span>
            </v-col>
            <v-col cols="6" class="Form-Columns-Dense text-right">
              <v-btn
                depressed
                small
                color="primary"
                @click="subHireFormDetails.sub_hire_job_charges.push({})"
                :disabled="
                  (subHireFormDetails &&
                    subHireFormDetails.status &&
                    subHireFormDetails.status == 'Invoice Updated') ||
                  subHireFormDetails.status == 'Completed'
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" class="Form-Columns-Dense px-0">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row
            v-for="(charge, i) in subHireFormDetails.sub_hire_job_charges"
            :key="i + keyUpdate"
            class="ma-0"
          >
            <v-col cols="1" class="Form-Columns-Dense">
              <span class="text-h6"> # {{ i + 1 }} </span>
            </v-col>
            <v-col cols="10">
              <v-row>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Charge *"
                    :items="allCharges"
                    itemText="charge_title"
                    itemValue="id"
                    :rules="[(v) => !!v || 'Charge is Required']"
                    :returnObject="true"
                    hide-details="auto"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.is_used &&
                        subHireFormDetails.status) ||
                      subHireFormDetails.status == 'Invoice Updated' ||
                      subHireFormDetails.status == 'Completed'
                    "
                    v-model="charge.charge"
                    :error-messages="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].charge
                        ? formErrors.sub_hire_job_charges[i].charge
                        : []
                    "
                    @input="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].charge
                        ? delete formErrors.sub_hire_job_charges[i].charge
                        : null;
                      setChargeDetails($event, i);
                      checkChargeErrors();
                    "
                    @change="setAdditionalChargeDetails()"
                  />
                </v-col>
                <v-col cols="12" lg="4" class="Form-Columns-Dense">
                  <InputField
                    label="Description"
                    readonly
                    :clearable="false"
                    hide-details="auto"
                    v-model="charge.description"
                    :error-messages="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].description
                        ? formErrors.sub_hire_job_charges[i].description
                        : []
                    "
                    @input="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].description
                        ? delete formErrors.sub_hire_job_charges[i].description
                        : null;
                      checkChargeErrors();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="1" class="Form-Columns-Dense">
                  <InputField
                    label="Cost GL No."
                    readonly
                    :clearable="false"
                    hide-details="auto"
                    v-model="charge.cost_gl_no"
                    :error-messages="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].cost_gl_no
                        ? formErrors.sub_hire_job_charges[i].cost_gl_no
                        : []
                    "
                    @input="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].cost_gl_no
                        ? delete formErrors.sub_hire_job_charges[i].cost_gl_no
                        : null;
                      checkChargeErrors();
                    "
                  />
                </v-col>
                <v-col cols="12" lg="1" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    label="TAX (%)"
                    readonly
                    :clearable="false"
                    hide-details="auto"
                    v-model="charge.tax_percentage"
                  />
                </v-col>
                <v-col cols="12" lg="2" class="Form-Columns-Dense">
                  <InputField
                    type="number"
                    :label="`Rate (${$globals.currency}) *`"
                    :disabled="!charge.charge"
                    :rules="[
                      (val) =>
                        val > 0 || 'Rate can not be less than or equal to 0',
                    ]"
                    hide-details="auto"
                    :readonly="
                      (subHireFormDetails &&
                        subHireFormDetails.is_used &&
                        subHireFormDetails.status) ||
                      subHireFormDetails.status == 'Invoice Updated' ||
                      subHireFormDetails.status == 'Completed'
                    "
                    v-model="charge.rate"
                    :error-messages="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].rate
                        ? formErrors.sub_hire_job_charges[i].rate
                        : []
                    "
                    @input="
                      formErrors &&
                      formErrors.sub_hire_job_charges &&
                      formErrors.sub_hire_job_charges[i] &&
                      formErrors.sub_hire_job_charges[i].rate
                        ? delete formErrors.sub_hire_job_charges[i].rate
                        : null;
                      checkChargeErrors();
                      setAdditionalChargeDetails();
                    "
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              lg="1"
              class="Form-Columns-Dense align-self-center text-right"
            >
              <v-btn
                color="secondary"
                :disabled="
                  (subHireFormDetails &&
                    subHireFormDetails.status &&
                    subHireFormDetails.status == 'Invoice Updated') ||
                  subHireFormDetails.status == 'Completed'
                "
                small
                depressed
                @click="removeCharge(i)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text class="pa-2"> </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          v-if="this.selectedLegs && this.selectedLegs.length > 0"
          color="primary"
          depressed
          :disabled="
            !isValid ||
            (subHireFormDetails &&
              subHireFormDetails.status &&
              subHireFormDetails.status == 'Invoice Updated') ||
            subHireFormDetails.status == 'Completed' ||
            subHireFormDetails.bid_request != null ||
            subHireFormDetails.lrfq_request != null
          "
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
      <CreateBidForm v-model="showBidForm" :bidFormDetails="bidDetails" />
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import CreateBidForm from "@/components/ModuleBased/dialogForms/CreateBidForm.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    AgGridVue,
    FormComponent,
    CreateBidForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    subHireID: {
      default: null,
    },
    jobStatus: {
      type: String,
    },
    legId: {
      default: null,
    },
    legNumber: {
      default: null,
    },

    daNumber: {
      default: null,
    },
    subHireNumber: {
      default: null,
    },
    laneId: {
      default: null,
    },
    customerId: {
      default: null,
    },
    daContractRate: {
      default: null,
    },
    daRateTaxAmount: {
      default: null,
    },
    daTotalRateWithTax: {
      default: null,
    },

    daLaneCode: {
      default: null,
    },
    daLaneType: {
      default: null,
    },
    status: {
      default: null,
    },
    containerType: {
      default: null,
    },
  },

  data() {
    return {
      jobDetails: {
        is_bid: "No",
      },
      daContainerType: null,
      bidDetails: {},
      lrfqDetails: {
        is_lrfq: "No",
      },
      bidRequestDetail: {},
      bidRequest: null,
      lrfqRequestDetail: {},
      lrfqRequest: null,
      getListCalled: false,
      showBidForm: false,
      today: new Date().toISOString().slice(0, 16),
      transporterList: [],
      bidList: [],
      lrfqList: [],
      selectedLegs: [],
      allCharges: [],

      formErrors: {},

      isValid: true,
      selectedLane: null,

      legList: [],

      daLane: null,
      daCustomer: null,
      daRate: 0,
      newRate: 0,
      customerDaAdditionalCharge: 0,
      daTotalWithoutTax: 0,
      daTotalTaxableAmount: 0,
      grandTotal: 0,
      daTaxAmount: null,
      daTotalRate: null,
      legRow: null,
      daLaneTitle: null,
      keyUpdate: 0,
      daNo: null,
      subHireNo: null,

      legNo: null,
      totalDa: 0,
      subHireStatus: null,
      subHireInitailStatus: null,
      subhireRate: 0,

      //transport rate calculation
      transportTotalAdditionalCharge: 0,
      transportTotalAmount: 0,
      transportTotalTaxableAmount: 0,
      transportGrandTotal: 0,

      marginRate: 0,
      marginPercent: 0,

      subHireFormDetails: {
        cargo_liability_insurance: "No",
        general_third_party_insurance: "No",
        sub_hire_job_charges: [],
      },

      laneServiceType: null,
      vendorVatApply: "Yes",

      headers: [
        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 150,
          checkboxSelection: (params) => {
            console.log("339", params.data);
            if (params && params.data && params.data.sub_hire_legs_checkbox) {
              return true;
            }
          },
        },
        {
          headerName: "DA",
          field: "da_no",
          minWidth: 120,
          onCellClicked: (params) => {
            if (params?.data?.da_id) {
              const path = {
                path: `/app/admin/delivery-advice/details/${params.data.da_id}`,
              };
              this.$router.push(path);
              console.log(path);
            }
          },
          cellStyle: (params) => {
            if (params?.data?.sub_hire_legs_checkbox) {
              return {
                color: "#FFA500",
                cursor: "pointer",
              };
            }
            return { cursor: "pointer" };
          },
        },

        {
          headerName: "BOE",
          field: "boe",
          minWidth: 120,
        },
        {
          headerName: "Sub Hire No.",
          field: "sub_hire_no",
          minWidth: 120,
        },

        {
          headerName: "Rate (AED)",
          field: "leg_rate",
          minWidth: 120,
        },
        {
          headerName: "Sub Hire Status",
          field: "sub_hire_status",
          minWidth: 100,
        },
        {
          headerName: "Bidding Id",
          field: "bidding_id",
          minWidth: 100,
        },
      ],
      legObject: {},

      defaultColDef: {
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.jobDetails.is_bid = "No";
        this.lrfqDetails.is_lrfq = "No";
        this.bidList = [];
        this.lrfqList = [];

        //container Type

        if (this.containerType) {
          this.daContainerType = this.containerType.types;
        }

        this.subHireStatus = this.status;
        this.subHireInitailStatus = this.jobStatus;

        this.getCharges();
        this.getTransporterList();
        this.daLane = this.laneId;
        this.legRow = this.legId;
        this.legNo = this.legNumber;

        this.daNo = this.daNumber;
        this.getLegListFromLane();

        if (this.subHireID && this.subHireInitailStatus !== "Created") {
          this.subHireNo = this.subHireNumber;
          this.getSubHireObject(this.subHireID);
        } else {
          this.getLegListObject(this.legId);
          this.daCustomer = this.customerId;
        }
      } else {
        this.legRow = null;
        this.resetForm();
      }
    },
  },

  computed: {
    showSubhireDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    context() {
      return { parentComponent: this };
    },

    totalRevenue() {
      if (this.subHireID && this.subHireInitailStatus != "Created") {
        this.Subhirerate = this.subHireFormDetails.da_rate;

        return (
          parseFloat(this.Subhirerate * this.totalDa) +
          parseFloat(this.customerDaAdditionalCharge)
        ).toFixed(2);
      } else {
        return (
          parseFloat(this.daRate) + parseFloat(this.customerDaAdditionalCharge)
        ).toFixed(2);
      }
    },

    grandTotalProperty() {
      return (
        parseFloat(this.totalRevenue) + parseFloat(this.daTotalTaxableAmount)
      ).toFixed(2);
    },
  },

  methods: {
    createBid() {
      this.showBidForm = true;
    },
    getLegListObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.trip
        .getLegListObject(id)
        .then((res) => {
          this.legObject = res.data;

          this.daRate = this.legObject.leg_rate;
          this.customerDaAdditionalCharge =
            this.legObject.customer_da_total_additional_charge;
          this.daTotalWithoutTax =
            parseFloat(this.daRate) +
            parseFloat(this.customerDaAdditionalCharge);
          this.daTotalTaxableAmount =
            this.legObject.customer_da_total_taxable_amount;
          this.grandTotal =
            parseFloat(this.daTotalWithoutTax) +
            parseFloat(this.daTotalTaxableAmount);
          this.daLaneTitle = this.legObject.leg_lane_title;
          this.laneServiceType = this.legObject.lane_service_type;
          this.daLane = this.legObject.laneId;
          if (this.laneServiceType == "local") {
            this.subHireFormDetails.tax_percentage = 5.0;
          } else if (this.laneServiceType == "Cross Border") {
            this.subHireFormDetails.tax_percentage = 0.0;
          }

          if (this.legObject.leg_container_details) {
            this.daContainerType =
              this.legObject.leg_container_details.types.join(", ");
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getSubHireObject(id) {
      this.$bus.$emit("showLoader", true);
      let params = {
        id: id,
        leg_id: this.legRow,
      };
      this.$api.subHire
        .getSubHireObject(params)
        .then((res) => {
          this.subHireFormDetails = res.data;

          this.subhireRate = res?.data?.da_rate;

          this.subHireStatus = this.subHireFormDetails.status;

          this.daLaneTitle = res.data.leg_lane_title;

          this.daCustomer = res.data.customer;

          this.totalDa = this.subHireFormDetails.sub_hire_leg_count;
          this.daTotalWithoutTax =
            parseFloat(this.subhireRate) +
            parseFloat(this.customerDaAdditionalCharge);
          this.grandTotal =
            parseFloat(this.daTotalWithoutTax) +
            parseFloat(this.daTotalTaxableAmount);
          this.setAdditionalChargeDetails();
          this.calculateTotals();

          this.subHireNo = res.data.sub_hire_id;
          this.daNo = res.data.da_number;
          this.laneServiceType = res.data.da_lane_service_type;

          if (
            this.subHireFormDetails &&
            this.subHireFormDetails.selected_legs &&
            this.subHireFormDetails.selected_legs.length > 0
          ) {
            this.setSelectedValues(this.subHireFormDetails.selected_legs);
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    marginPercentage() {
      let profit = this.marginRate;
      this.marginPercent = Number((profit / this.totalRevenue) * 100).toFixed(
        2
      );
    },

    resetForm() {
      (this.selectedLegs = []), (this.totalDa = 0);
      this.legList = null;
      this.selectedLane = null;
      this.selectedRows = null;
      this.transportTotalAdditionalCharge = 0;
      this.transportTotalAmount = 0;
      this.marginPercent = 0;
      this.marginRate = 0;
      this.transportGrandTotal = 0;
      this.total_taxable_amount = 0;

      this.subHireFormDetails = {
        cargo_liability_insurance: "No",
        general_third_party_insurance: "No",
        sub_hire_job_charges: [],
      };
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    calculateTotals() {
      if (this.subHireID && this.subHireInitailStatus != "Created") {
        this.totalDa = this.totalDa;
        console.log("this is trigger-000-");

        let a = (
          parseFloat(this.subHireFormDetails.da_rate) * this.totalDa
        ).toFixed(2);

        this.subhireRate = a;

        // this.legObject.customer_rate = this.subHireFormDetails.da_rate;

        this.legObject.customer_da_total_additional_charge =
          this.subHireFormDetails.customer_da_total_additional_charge;
        this.legObject.customer_da_total_taxable_amount =
          this.subHireFormDetails.customer_da_total_taxable_amount;
      }
      console.log("nigam this is ", this.totalDa);

      this.daRate = (
        parseFloat(this.legObject.leg_rate) * this.totalDa
      ).toFixed(2);
      if (this.totalDa > 0) {
        //Default DA Additional Charge
        // let main_da_additional_charge = parseFloat(
        //   this.legObject.customer_da_total_additional_charge
        // );
        //Default DA Tax Amount
        // let main_da_total_taxable_amount = parseFloat(
        //   this.legObject.customer_da_total_taxable_amount
        // );

        let selectDaAdditionalCharge = 0;
        let selectDaTotalTaxableAmount = 0;
        this.selectedLegs.forEach((charge) => {
          selectDaAdditionalCharge += parseFloat(
            charge.customer_da_total_additional_charge
          );

          selectDaTotalTaxableAmount += parseFloat(
            charge.customer_da_total_taxable_amount
          );
        });

        this.customerDaAdditionalCharge = Number(
          selectDaAdditionalCharge
        ).toFixed(2);

        this.daTotalTaxableAmount = Number(selectDaTotalTaxableAmount).toFixed(
          2
        );
      } else {
        this.customerDaAdditionalCharge = 0;
        this.daTotalTaxableAmount = 0;
        // this.customerDaAdditionalCharge = Number(
        //   this.legObject.customer_da_total_additional_charge
        // ).toFixed(2);

        // this.daTotalTaxableAmount = Number(
        //   this.legObject.customer_da_total_taxable_amount
        // ).toFixed(2);
      }

      this.setAdditionalChargeDetails();
      this.setTotalTaxableAmount();
    },
    setSelectedRows() {
      this.totalDa = 0;
      this.selectedLegs = this.gridApi.getSelectedRows();

      let additionalLeg = this.selectedLegs.length;

      this.totalDa += additionalLeg;
      // if (this.subHireID && this.subHireInitailStatus != "Created") {
      //   this.subhireRate = this.subHireFormDetails.da_rate;
      // } else {
      //   this.daRate = this.daRate;
      // }

      this.calculateTotals();
    },
    setSelectedValues(selectedLegs = []) {
      const interval = setInterval(() => {
        if (this.gridApi) {
          clearInterval(interval);
          this.gridApi.forEachNode((node) => {
            if (selectedLegs.indexOf(node.data.id) > -1) {
              node.setSelected(true);
            }
          });
        }
      }, 400);
    },
    getLegListFromLane(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.$api.subHire
        .getLegListFromLane(this.legRow, params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.legList = res.data;

          let selectedLegArray = [];
          selectedLegArray.push(this.legRow);
          this.setSelectedValues(selectedLegArray);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.transporterList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getBidList(params = {}) {
      if (this.jobDetails.is_bid === "Yes") {
        this.bidRequest = null;
        this.$bus.$emit("showLoader", true);
        params.leg_id = this.legRow;
        this.$api.bidding
          .getBidListForSubHire(params)
          .then((res) => {
            this.bidRequestDetail = res.data;
            this.bidList = res.data.bid_list;
            this.bidDetails.lane = res.data.lane_id;
            this.bidDetails.container_type_count = res.data.da_container_type;
            this.bidDetails.job_type = res.data.job_type;
            this.bidDetails.lane_title = res.data.da_lane_title;
            this.bidDetails.container_type = this.daContainerType;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.$bus.$emit("showLoader", false);
          });
      } else {
        this.subHireFormDetails.transporter = null;
        this.subHireFormDetails.rate = null;
      }
    },
    getLrfqList(params = {}) {
      if (this.lrfqDetails.is_lrfq === "Yes") {
        this.lrfqRequest = null;
        this.$bus.$emit("showLoader", true);
        params.leg_id = this.legRow;
        this.$api.lrfq
          .getLrfqListForSubHire(params)
          .then((res) => {
            this.lrfqRequestDetail = res.data;
            this.lrfqList = res.data.lrfq_list;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.$bus.$emit("showLoader", false);
          });
      } else {
        this.subHireFormDetails.transporter = null;
        this.subHireFormDetails.rate = null;
      }
    },
    checkVatApplicablity() {
      // Find the selected transporter in the transporterList
      let selectedTransporter = this.transporterList.find(
        (transporter) => transporter.id == this.subHireFormDetails.transporter
      );

      // Check if selectedTransporter is found
      if (selectedTransporter) {
        this.vendorVatApply = selectedTransporter.vat_applicability;
        if (this.vendorVatApply == "No") {
          this.subHireFormDetails.tax_percentage = 0.0;
          this.setAdditionalChargeDetails();
        } else {
          if (this.laneServiceType == "local") {
            this.subHireFormDetails.tax_percentage = 5.0;
            this.setAdditionalChargeDetails();
          } else if (this.laneServiceType == "Cross Border") {
            this.subHireFormDetails.tax_percentage = 0.0;
            this.setAdditionalChargeDetails();
          }
        }
      } else {
        // Handle the case when selectedTransporter is not found
        // Optionally, you can set a default value for vendorVatApply here
        // this.vendorVatApply = defaultValue;
      }
    },

    setTransporterDetail() {
      this.subHireFormDetails.transporter = this.bidRequest.transporter;
      this.subHireFormDetails.rate = this.bidRequest.rate;
      this.setAdditionalChargeDetails();
    },
    setTransporterDetailFromLrfq() {
      this.subHireFormDetails.transporter = this.lrfqRequest.transporter;
      this.subHireFormDetails.rate = this.lrfqRequest.rate;
      this.setAdditionalChargeDetails();
    },

    setChargeDetails(val, index) {
      if (val) {
        this.subHireFormDetails.sub_hire_job_charges[index].charge = val.id;
        this.subHireFormDetails.sub_hire_job_charges[index].charge_title =
          val.charge_title;
        this.subHireFormDetails.sub_hire_job_charges[index].cost_gl_no =
          val.cost_gl_no;
        this.subHireFormDetails.sub_hire_job_charges[index].description =
          val.description;
        this.subHireFormDetails.sub_hire_job_charges[index].rate = val.rate;
        this.subHireFormDetails.sub_hire_job_charges[index].tax_percentage =
          val.tax_percentage;

        this.keyUpdate++;
      } else if (
        this.subHireFormDetails &&
        this.subHireFormDetails.sub_hire_job_charges
      ) {
        this.subHireFormDetails.sub_hire_job_charges[index] = {};
      }
    },
    setAdditionalChargeDetails() {
      this.transportTotalAdditionalCharge = 0;
      this.subHireFormDetails.sub_hire_job_charges.forEach((charge) => {
        if (charge.rate) {
          let roundRate = parseFloat(charge.rate).toFixed(2);
          this.transportTotalAdditionalCharge += parseFloat(roundRate);
        }
      });

      this.subHireFormDetails.total_cost = (
        (parseFloat(this.subHireFormDetails.rate) +
          parseFloat(this.transportTotalAdditionalCharge)) *
        parseFloat(this.totalDa)
      ).toFixed(2);

      //margin rate and percentage
      this.marginRate = (
        parseFloat(this.totalRevenue) -
        parseFloat(this.subHireFormDetails.total_cost)
      ).toFixed(2);
      let profit = this.marginRate;
      this.marginPercent = (
        (profit / parseFloat(this.totalRevenue)) *
        100
      ).toFixed(2);

      this.setTotalTaxableAmount();
    },
    setTotalTaxableAmount() {
      this.subHireFormDetails.total_taxable_amount = 0;
      this.subHireFormDetails.sub_hire_job_charges.forEach((charge) => {
        if (charge.rate) {
          this.subHireFormDetails.total_taxable_amount +=
            (parseFloat(charge.rate) * parseFloat(charge.tax_percentage)) / 100;
        }
      });
      if (this.subHireFormDetails.rate) {
        this.subHireFormDetails.total_taxable_amount +=
          (parseFloat(this.subHireFormDetails.rate) *
            parseFloat(this.subHireFormDetails.tax_percentage)) /
          100;
        this.subHireFormDetails.total_taxable_amount = (
          this.subHireFormDetails.total_taxable_amount *
          parseFloat(this.totalDa)
        ).toFixed(2);
      }

      this.subHireFormDetails.total_cost_with_tax = (
        parseFloat(this.subHireFormDetails.total_cost) +
        parseFloat(this.subHireFormDetails.total_taxable_amount)
      ).toFixed(2);
    },
    getCharges() {
      this.$bus.$emit("showLoader", true);
      this.$api.charge
        .getChargeList({ limit: "all", is_active: "true" })
        .then((res) => {
          this.allCharges = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    addCharge() {
      this.subHireFormDetails.sub_hire_job_charges.push({});
    },

    removeCharge(i) {
      this.subHireFormDetails.sub_hire_job_charges.splice(i, 1);
      this.setAdditionalChargeDetails();
    },

    checkChargeErrors() {
      if (
        this.formErrors &&
        this.formErrors.sub_hire_job_charges &&
        (this.formErrors.sub_hire_job_charges.length == 0 ||
          this.formErrors.sub_hire_job_charges.every(
            (obj) => Object.keys(obj).length == 0
          ))
      ) {
        delete this.formErrors.sub_hire_job_charges;
      }
    },

    submitForm(data = {}) {
      this.formErrors = {};
      this.$bus.$emit("showLoader", true);
      let bidRequesId = null;
      let lrfqRequestId = null;
      if (this.bidRequest) {
        bidRequesId = this.bidRequest.id;
      }
      if (this.lrfqRequest) {
        lrfqRequestId = this.lrfqRequest.id;
      }

      data = {
        current_leg: this.legId,
        ...this.subHireFormDetails,
        selected_legs: this.selectedLegs.map((leg) => leg.id),
        bid_request: bidRequesId,
        lrfq_request: lrfqRequestId,
      };
      // if (data.selected_legs.indexOf(this.legRow) == -1) {
      //   data.selected_legs.push(this.legRow);
      // }
      if (!this.subHireID) {
        this.$api.subHire
          .submitSubhireData(data)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Sucess",
              color: "success",
            });
            this.resetForm();
            this.showSubhireDialog = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Assigned Sub hire on leg(s)!",
              color: "error",
            });
            if (err.data) {
              this.formErrors = err.data;
            }
          });
      } else {
        data = {
          id: this.subHireID,
          data: { ...data },
        };
        if (this.subHireInitailStatus == "Created") {
          data.data.status = "Pending";
        }

        this.$api.subHire
          .updateSubHireJob(data)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Sucess",
              color: "success",
            });
            this.resetForm();
            this.showSubhireDialog = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't Assigned Sub hire on leg(s)!",
              color: "error",
            });
            if (err.data) {
              this.formErrors = err.data;
            }
          });
      }
    },
  },
};
</script>
