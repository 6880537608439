import Blank from "@/layouts/blank.vue";

import CompanyRequestList from "@/pages/admin/company-management/new_company_request/CompanyRequestList.vue";

const companyRequestRoutes = [
  {
    path: "new_company_request",
    name: "app-admin-company-management-new_company_request",
    component: Blank,
    children: [
      {
        path: "/",
        redirect: "/app/admin/company-management/new_company_request/list",
      },
      {
        path: "list",
        name: "app-admin-company-management-new_company_request-list",
        component: CompanyRequestList,
      },
    ],
  },
];

export default companyRequestRoutes;
