var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showConfirmationDialogue),callback:function ($$v) {_vm.showConfirmationDialogue=$$v},expression:"showConfirmationDialogue"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',[_vm._v("Send to SAP")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showConfirmationDialogue = false}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card',{staticClass:"elevation-12 rounded-xl"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-row',{staticClass:"mt-2"},[(_vm.localPostDate)?_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('DateField',{attrs:{"inputProps":{
                dense: true,
                label: 'Post Date',
                id: 'post-form-date',
                clearable: false,
                'hide-details': 'auto',
                'error-messages': _vm.formErrors.post_date || [],
              },"disabled":!_vm.localPostDate},on:{"input":function($event){delete _vm.formErrors.post_date}},model:{value:(_vm.localPostDate),callback:function ($$v) {_vm.localPostDate=$$v},expression:"localPostDate"}})],1):_vm._e()],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('p',{staticClass:"text-body-1 text-center text-subtitle-2 mb-0"},[_vm._v(" "+_vm._s(_vm.text)+" ")])])],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end py-4"},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","color":"grey"},on:{"click":_vm.reject}},[_vm._v(" "+_vm._s(_vm.rejectButton)+" ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.confirmed}},[_vm._v(" "+_vm._s(_vm.confirmButton)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }