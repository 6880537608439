import { incidentLog } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getIncidentLogList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(incidentLog.incidentLogBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getIncidentObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${incidentLog.incidentLogBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addIncident(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(incidentLog.incidentLogBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateIncident(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${incidentLog.incidentLogBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateIncident(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${incidentLog.incidentLogBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
