<template>
  <v-dialog width="1300" v-model="showSAPDriverBonusDialog">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> Driver Bonus Detail </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showSAPDriverBonusDialog = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-row class="d-flex justify-center">
          <v-col cols="4">
            <v-tabs fixed-tabs v-model="detailsTab">
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab class="rounded-lg">Trip Bonus</v-tab>
              <v-tab class="rounded-lg">Lease Bonus</v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-tabs-items v-model="detailsTab">
          <v-tab-item class="Height-Class">
            <v-col cols="12" class="pt-3 pb-1 pl-1 pr-1">
              <AgGridVue
                style="width: 100%; height: calc(70vh - 270px)"
                class="small-table ag-theme-alpine"
                id="advancedSearchGrid"
                :columnDefs="tripHeaders"
                :context="context"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :grid-options="gridOptions"
                :suppressDragLeaveHidesColumns="true"
                :rowData="tripBonusList"
              ></AgGridVue>
            </v-col>
          </v-tab-item>
          <v-tab-item class="Height-Class">
            <v-col cols="12" class="pt-3 pb-1 pl-1 pr-1">
              <AgGridVue
                style="width: 100%; height: calc(70vh - 270px)"
                class="small-table ag-theme-alpine"
                id="advancedSearchGrid"
                :columnDefs="leaseHeaders"
                :context="context"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :grid-options="gridOptions"
                :suppressDragLeaveHidesColumns="true"
                :rowData="leaseBonusList"
              ></AgGridVue>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
        <v-col
          cols="12"
          class="px-0 pt-0 pb-2 d-flex justify-end"
          v-if="totalItems > 0"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-card-text>

      <v-card-actions class="justify-end"> </v-card-actions>
      <SAPBonusUpdateDialog
        v-model="SAPBonusUpdate"
        :payoutId="payoutId"
        :empId="empId"
        :tripId="tripId"
        :startDate="StartDate"
        :endDate="endDate"
        :legId="legId"
        :legBonus="legBonus"
        @refreshList="getSapPayoutDataForEachLeg()"
      />
      <SAPBonusRemarksDialog
        v-model="SAPBonusRemarks"
        :empId="empId"
        :startDate="StartDate"
        :endDate="endDate"
        :legId="legId"
        :legBonus="legBonus"
        @refreshList="getSapPayoutDataForEachLeg()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SAPRateEditButton from "@/components/AgGridComponents/SAPRateEditButton.vue";
import Pagination from "@/components/General/Pagination.vue";
import SAPBonusUpdateDialog from "@/components/ModuleBased/dialogForms/SAPBonusUpdateDialog.vue";
import SAPBonusRemarksDialog from "@/components/ModuleBased/dialogForms/SAPBonusRemarksDialog.vue";

export default {
  components: {
    InputField,
    MultiSelect,
    AgGridVue,
    SAPRateEditButton,
    Pagination,
    SAPBonusUpdateDialog,
    SAPBonusRemarksDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    driverPayoutData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    value(val) {
      this.getSapPayoutDataForEachLeg();
      if (val) {
      } else {
      }
    },
  },
  computed: {
    showSAPDriverBonusDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      isValid: true,
      detailsTab: 0,
      legBonus: 0,
      legId: [],
      empId: null,
      SAPBonusUpdate: false,
      SAPBonusRemarks: false,
      tripBonusList: [],
      leaseBonusList: [],
      payoutId: null,
      tripId: null,
      StartDate: null,
      endDate: null,
      isTextInput: false,
      formErrors: {},
      branchDetails: {},
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      tripHeaders: [
        {
          headerName: "Trips",
          field: "trip_no",
          tooltipField: "trip_no",
          sortable: true,
        },
        {
          headerName: "Leg",
          field: "leg_no",
          tooltipField: "leg_no",
          sortable: true,
        },
        // {
        //   headerName: "No. of Trips",
        //   field: "no_of_trips",
        //   tooltipField: "no_of_trips",
        //   sortable: true,
        // },

        {
          headerName: "Bonus",
          field: "total_bonus",
          sortable: true,
          minWidth: 65,
          tooltipField: "total_bonus",
        },
        {
          headerName: "Depot",
          field: "depot",
          sortable: true,
          minWidth: 65,
          tooltipField: "depot",
        },
        {
          headerName: "Emp Id",
          field: "emp_id",
          sortable: true,
          minWidth: 65,
          tooltipField: "emp_id",
        },

        {
          headerName: "Driver Name",
          field: "employee_name",
          tooltipField: "employee_name",
          minWidth: 45,
          sortable: true,
        },
        {
          headerName: "Channel",
          field: "agent",
          tooltipField: "agent",
          minWidth: 45,
          sortable: true,
          valueGetter: (params) => params.data?.agent ?? "N/A",
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "SAPRateEditButton",
          pinned: "right",
          minWidth: 150,
        },
      ],
      leaseHeaders: [
        {
          headerName: "No. of Trips",
          field: "no_of_trips",
          tooltipField: "no_of_trips",
          sortable: true,
        },
        {
          headerName: "Bonus",
          field: "total_bonus",
          sortable: true,
          minWidth: 65,
          tooltipField: "total_bonus",
        },
        {
          headerName: "Emp Id",
          field: "emp_id",
          sortable: true,
          minWidth: 65,
          tooltipField: "emp_id",
        },

        {
          headerName: "Driver Name",
          field: "employee_name",
          tooltipField: "employee_name",
          minWidth: 45,
          sortable: true,
        },
        // {
        //   headerName: "Actions",
        //   field: "actions",
        //   cellRendererFramework: "SAPRateEditButton",
        //   pinned: "right",
        //   minWidth: 150,
        // },
      ],
    };
  },
  methods: {
    editSAPRate(data) {
      this.empId = data?.emp_id;
      this.payoutId = data?.id;
      this.tripId = data?.trip_id;
      this.legId = [];
      if (data?.leg_id) {
        this.legId.push(data.leg_id);
      }

      this.StartDate = data.start_date;
      this.endDate = data.end_date;

      this.legBonus = data?.total_bonus;
      this.SAPBonusUpdate = true;
    },

    deleteSAPRate(data) {
      this.empId = data?.emp_id;

      this.legId = [];
      if (data?.leg_id) {
        this.legId.push(data.leg_id);
      }

      this.StartDate = data.start_date;
      this.endDate = data.end_date;

      this.legBonus = data?.total_bonus;
      this.SAPBonusRemarks = true;
    },
    getSapPayoutDataForEachLeg(params = {}) {
      this.$bus.$emit("showLoader", true);

      const mergedParams = {
        sap_payout_id: this.driverPayoutData.id,
        emp_id: this.driverPayoutData.personnel_no,
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
      };

      this.$api.leaseDriverBonus
        .getSapPayoutDataForEach(mergedParams)
        .then((res) => {
          this.bonusData = res.data;
          this.tripBonusList = res.data.filter((item) => item.trip_no);
          this.leaseBonusList = res.data.filter((item) => !item.trip_no);

          this.totalItems = res.data.length;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    prevPage() {
      this.pageNo--;
      this.getSapPayoutDataForEachLeg();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSapPayoutDataForEachLeg();
    },
    nextPage() {
      this.pageNo++;
      this.getSapPayoutDataForEachLeg();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resetForm() {
      this.$emit("refreshList");
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
  },
};
</script>
