<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="mr-4 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          :disabled="params && params.data && params.data.status == 'Approved'"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>
     <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="mr-4 ml-1"
          color="error"
          v-on="on"
          v-bind="attrs"
          :disabled="params && params.data && params.data.status == 'Approved'"
          @click="deleteItem"
        >
          mdi-delete
        </v-icon>
      </template>
      <span>Delete</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editSAPRate(this.params.data);
    },
    deleteItem() {
      this.params.context.parentComponent.deleteSAPRate(this.params.data);
    },
  },
};
</script>

<style></style>
