<template>
  <v-container fluid class="fill-height">
    <v-row class="">
      <!-- Left Side - Image Background -->
      <v-col
        cols="7"
        class="d-flex align-center justify-center Login-Image-Background"
      >
      </v-col>

      <v-col cols="5" class="d-flex align-center justify-center py-0 px-0 pl-0">
        <v-card flat class="pa-7 rounded-lg" width="100%" max-width="400">
          <v-card-title class="d-flex justify-center">
            <img
              class="Brand-Logo mb-5"
              alt="Brand Logo"
              src="@/assets/images/brand-logo.png"
              style="max-width: 150px"
            />
          </v-card-title>
          <v-card-text>
            <span class="font-weight-bold text-h5">Welcome to, Momentum</span>
          </v-card-text>

          <v-card-text>
            <v-form
              @submit.prevent="submitForm"
              ref="loginForm"
              v-model="isValid"
            >
              <!-- Username Field -->
              <v-text-field
                label="Username"
                required
                outlined
                dense
                v-model="userDetails.username"
                :rules="[(v) => !!v || 'Username is required']"
              ></v-text-field>

              <!-- Password Field -->
              <v-text-field
                label="Password"
                required
                outlined
                dense
                v-model="userDetails.password"
                :type="passwordEye ? 'text' : 'password'"
                :append-icon="passwordEye ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || 'Password is required']"
                @click:append="passwordEye = !passwordEye"
              ></v-text-field>

              <!-- Submit Button -->
              <v-btn
                type="submit"
                color="primary"
                block
                :loading="btnLoading"
                :disabled="!isValid"
              >
                Login
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions class="d-flex flex-column align-center px-0">
            <v-divider class="my-4"></v-divider>

            <!-- Row of buttons -->
            <v-row class="ma-0" justify="center">
              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-btn
                  text
                  color="primary"
                  class="text-capitalize"
                  @click="forgotPassword"
                >
                  <v-icon left>mdi-key</v-icon> Forgot Password?
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-btn
                  text
                  color="primary"
                  class="text-capitalize"
                  @click="forgotUsername"
                >
                  <v-icon left>mdi-account-question</v-icon> Forgot Username?
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-btn
                  text
                  color="primary"
                  class="text-capitalize"
                  @click="signUp"
                >
                  <v-icon left>mdi-account-plus</v-icon> New User Request
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="d-flex justify-center">
                <v-btn
                  text
                  color="primary"
                  class="text-capitalize"
                  @click="companyRequest"
                >
                  <v-icon left>mdi-domain</v-icon> Company Request
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { encryptText } from "@/utils/functions";

export default {
  components: { InputField },
  layout: "login",
  data() {
    return {
      userDetails: {},
      btnLoading: false,
      password: "Password",
      passwordEye: false,
      isValid: false,
    };
  },
  methods: {
    submitForm() {
      this.btnLoading = true;
      let payload = {
        ...this.userDetails,
      };
      if (this.userDetails && this.userDetails.password) {
        payload.password = encryptText(this.userDetails.password);
      }
      this.$api.authentication
        .login(payload)
        .then(({ data }) => {
          localStorage.setItem("user_type", data.user_type);
          localStorage.setItem("user", data.token);
          localStorage.setItem(
            "permissions",
            JSON.stringify(data.detail.permissions)
          );
          this.$api.authentication
            .getProfileInfo()
            .then((res) => {
              this.$bus.$emit("showLoader", false);
              localStorage.setItem("userProfile", JSON.stringify(res.data));

              const preference =
                res && res.data && res.data.user_preferences
                  ? res.data.user_preferences
                  : {};

              localStorage.setItem(
                "userPreferences",
                JSON.stringify(preference)
              );
              setTimeout(() => {
                if (
                  this.$route &&
                  this.$route.query &&
                  this.$route.query.redirectUrl
                ) {
                  this.$router.push(this.$route.query.redirectUrl);
                } else {
                  this.$router.push(`/app/${data.user_type.toLowerCase()}`);
                }
              }, 500);
            })
            .catch((err) => {
              this.$bus.$emit("showLoader", false);
            });

          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
          if (err && err.data && err.data.non_field_errors) {
            this.$bus.$emit("showToastMessage", {
              message: err.data.non_field_errors[0],
              color: "error",
              hasActions: true,
            });
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Unable to log in with provided credentials.",
              color: "error",
            });
          }
        });
    },
    forgotPassword() {
      this.$router.push("/app/forgot-password");
    },
    forgotUsername() {
      this.$router.push("/app/forgot-username");
    },
    signUp() {
      this.$router.push("/app/signup");
    },
    companyRequest() {
      this.$router.push("/app/company-request");
    },
  },
};
</script>

<style scoped>
.Screen-Height {
  height: 100vh;
}
.Brand-Logo {
  width: 250px;
}
.Login-Image-Background {
  background: url("@/assets/images/brand-logo1.png") no-repeat center center;
  background-size: cover;
  height: 100vh;
  padding: 0 20px;
}
</style>
