import SapBonusPayoutList from "@/pages/admin/sap_bonus_payout/SapBonusPayoutList.vue";
import PayoutList from "@/pages/admin/sap_bonus_payout/PayoutList.vue";

const SapBonusPayoutRoutes = [
  {
    path: "sap_bonus_payout",
    name: "app-admin-sap-bonus-payout",
    component: SapBonusPayoutList,
  },
  {
    path: "sap_bonus_payout/payout/:id",
    name: "app-admin-payout-list",
    component: PayoutList,
  },
];

export default SapBonusPayoutRoutes;
