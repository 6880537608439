<template>
  <v-dialog max-width="50%" v-model="showSplitLegRateForm" persistent>
    <v-card class="rounded-lg" variant="outlined">
      <v-card-title class="secondary white--text">
        <span class="text-capitalize"> Split DA </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="resetForm, (showSplitLegRateForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card variant="outlined">
        <!-- Row with Delivery Advice Information -->
        <v-row class="ma-0">
          <v-col cols="4" class="d-flex justify-start align-center">
            <span class="mr-2 mt-1 pl-3">Delivery Advice No.</span>
          </v-col>
          <v-col cols="5" class="d-flex justify-center align-center">
            <span class="mr-2 mt-1">Payment Terms</span>
          </v-col>
          <v-col cols="3" class="d-flex justify-end align-center pr-14">
            <span class="mr-2 mt-1">DA Base Rate</span>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col cols="4" class="d-flex justify-start align-center">
            <span class="mr-2 mt-1 pl-3 font-weight-bold">{{
              daDetails ? daDetails.da_no : ""
            }}</span>
          </v-col>
          <v-col cols="5" class="d-flex justify-center align-center">
            <span class="mr-2 mt-1 pr-3 font-weight-bold">{{
              daDetails ? daDetails.payment_term : ""
            }}</span>
          </v-col>
          <v-col cols="3" class="d-flex justify-end pr-10">
            <v-chip
              class="font-weight-bold pl-5"
              color="grey"
              label
              style="border-radius: 15px; display: flex; align-items: center"
            >
              <span class="mr-1" style="color: white">{{
                daDetails.rate
              }}</span>
              <span
                class="font-weight-bold"
                style="font-size: 15px; color: white"
                >{{ $globals.currency }}</span
              >
            </v-chip>
          </v-col>
        </v-row>
      </v-card>

      <v-card v-if="type && type === 'Base'" variant="outlined">
        <v-form ref="chargeForm" v-if="showSplitLegRateForm" v-model="isValid">
          <v-row class="ma-0" style="background-color: #e0e0e0">
            <v-col cols="3" class="d-flex justify-start">
              <span class="font-weight-medium text-secondary">Leg No</span>
            </v-col>
            <v-col cols="7" class="d-flex justify-center pr-10">
              <span class="font-weight-medium text-secondary">Lane</span>
            </v-col>
            <v-col cols="2" class="d-flex justify-end pr-7">
              <span class="font-weight-medium text-secondary">Rate</span>
            </v-col>
          </v-row>

          <!-- Split Rate Row -->
          <v-row class="d-flex justify-between align-center ma-2">
            <v-col cols="3" class="Form-Columns d-flex justify-start">
              {{ legNo }}
            </v-col>
            <v-col cols="7" class="Form-Columns d-flex justify-center">
              {{ daDetails.lane_title }}
            </v-col>

            <v-col cols="2" class="Form-Columns d-flex justify-end pl-3">
              <InputField
                type="number"
                :label="`Leg Rate (${$globals.currency}) *`"
                required
                hide-details="auto"
                readonly
                v-model="splitRate"
                disabled
                :error-messages="formErrors.rate"
                @input="delete formErrors.rate"
              />
            </v-col>
          </v-row>

          <!-- Split DA Row -->
          <v-row class="d-flex justify-between align-center ma-2">
            <v-col cols="3" lg="3" class="Form-Columns d-flex justify-start">
              <span>Split DA</span>
            </v-col>
            <v-col
              cols="7"
              lg="7"
              class="Form-Columns d-flex justify-center pr-5"
            >
              <span>N/A</span>
            </v-col>
            <v-col cols="2" lg="2" class="Form-Columns d-flex justify-end">
              <InputField
                type="number"
                :label="`Split Rate (${$globals.currency})`"
                required
                :rules="[
                  (val) => !!val || 'Rate is required!',
                  (val) => Number(val) >= 0 || 'Rate cannot be negative!',
                  (val) => {
                    return (
                      Number(val) < Number(this.daDetails.rate) ||
                      'Split rate cannot be greater than the base rate!'
                    );
                  },
                ]"
                hide-details="auto"
                v-model="splitLegRateDetails.rate"
                :error-messages="formErrors.rate"
                @input="delete formErrors.rate, updateTotalRate()"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Else Card for Split Leg Rate -->
      <v-card v-else variant="outlined">
        <v-row
          class="mt-4 ml-0 pl-0 pr-0 mr-0"
          style="background-color: #e0e0e0; border-radius: 3px"
        >
          <v-col cols="3" class="py-1 d-flex justify-left">
            <span class="font-weight-medium text-secondary">Leg No</span>
          </v-col>
          <v-col cols="7" class="py-1 d-flex justify-center">
            <span class="font-weight-medium text-secondary">Lane</span>
          </v-col>
          <v-col cols="2" class="py-1 d-flex justify-end">
            <span class="font-weight-medium text-secondary pr-12">Rate</span>
          </v-col>
        </v-row>

        <v-form ref="chargeForm" v-if="showSplitLegRateForm" v-model="isValid">
          <v-row
            class="d-flex justify-between align-center ma-2"
            v-for="(item, i) in legDetails"
            :key="i"
          >
            <v-divider></v-divider>
            <v-col cols="12" lg="3" class="Form-Columns d-flex justify-left">
              <span>{{ item.leg_id }}</span>
            </v-col>
            <v-col cols="12" lg="7" class="Form-Columns d-flex justify-center">
              <span>{{ item.leg_lane_title }}</span>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              class="Form-Columns d-flex justify-end pl-3"
            >
              <InputField
                type="number"
                :label="`Leg Rate (${$globals.currency}) *`"
                required
                hide-details="auto"
                v-model="item.rate"
                :rules="[
                  (val) => !!val || 'Rate is required!',
                  (val) => {
                    return (
                      totalRate() <= Number(daDetails.rate) ||
                      'Total rate cannot exceed the base rate!'
                    );
                  },
                ]"
                :error-messages="formErrors[`rate_${i}`]"
                @input="
                  updateTotalRate;
                  delete formErrors[`rate_${i}`];
                "
              />
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>

          <v-row class="ma-0">
            <v-col cols="12" lg="3" class="Form-Columns d-flex justify-left">
              <span class="pl-2">Split DA</span>
            </v-col>
            <v-col cols="12" lg="7" class="Form-Columns d-flex justify-center">
              <span class="pl-2">N/A</span>
            </v-col>

            <v-col
              cols="12"
              lg="2"
              class="Form-Columns d-flex justify-end pr-3"
            >
              <InputField
                type="number"
                :label="`Split Rate (${$globals.currency}) *`"
                required
                :rules="[
                  (val) => !!val || 'Rate is required!',
                  (val) => {
                    return (
                      Number(val) <= Number(this.daDetails.rate) ||
                      'Split rate cannot be greater than the base rate!'
                    );
                  },
                  (val) => {
                    return (
                      this.totalRate() <= Number(this.daDetails.rate) ||
                      'Total rate cannot exceed the base rate!'
                    );
                  },
                ]"
                hide-details="auto"
                v-model="splitLegRateDetails.rate"
                :error-messages="formErrors.splitRate"
                @input="handleSplitRateInput"
              />
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
        </v-form>
      </v-card>

      <!-- Total Amount Row -->
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="11" class="d-flex justify-end ml-13">
            <span class="mr-2 font-weight-bold mt-1">Total Amount:</span>
            <v-chip
              class="font-weight-bold"
              color="success"
              label
              style="border-radius: 15px; display: flex; align-items: center"
            >
              <span class="mr-1">{{ totalAmount }}</span>

              <span class="font-weight-bold" style="font-size: 15px">{{
                $globals.currency
              }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <!-- Action Buttons -->
      <v-card-actions class="d-flex justify-end mt-5">
        <v-btn
          v-if="type && type === 'Base'"
          color="primary"
          depressed
          :disabled="isBaseAddButtonDisabled"
          @click="submitForm()"
        >
          Add
        </v-btn>
        <v-btn
          v-else
          color="primary"
          depressed
          :disabled="isAddButtonDisabled"
          @click="gatherSplitRateData()"
        >
          Add
        </v-btn>

        <v-btn class="ml-3" color="secondary" depressed @click="resetForm">
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  data() {
    return {
      adjustedRate: null,

      legNo: null,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Base",
    },
    legDetails: [],

    daDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.splitRate = this.daDetails.rate;
        this.legDetails.forEach((leg) => {
          this.legNo = leg.leg_id;
        });
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showSplitLegRateForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    adjustedRate() {
      return this.daDetails.rate - (this.splitLegRateDetails.rate || 0);
    },

    isBaseAddButtonDisabled() {
      const splitRate = this.splitLegRateDetails.rate;
      const daRate = Number(this.daDetails.rate);
      return !splitRate || Number(splitRate) > daRate;
    },
    isAddButtonDisabled() {
      const splitRate =
        this.splitLegRateDetails.rate !== null
          ? Number(this.splitLegRateDetails.rate)
          : null;
      const daRate = Number(this.daDetails.rate);

      const totalLegRates = this.legDetails.reduce(
        (total, leg) => total + Number(leg.rate),
        0
      );

      const allRatesFilled = this.legDetails.every(
        (leg) => leg.rate && Number(leg.rate) <= daRate
      );

      const totalRate = totalLegRates + (splitRate || 0);

      // Disable the button if:
      // - splitRate is null
      // - splitRate is greater than the DA rate
      // - not all leg rates are filled and valid
      // - the total rate exceeds the DA rate
      // - the total rate is less than the DA rate
      return (
        splitRate === null ||
        splitRate > daRate ||
        !allRatesFilled ||
        totalRate > daRate ||
        totalRate < daRate
      );
    },
    totalAmount() {
      const splitDARate = Number(this.splitLegRateDetails.rate) || 0;
      let totalAmount = 0;

      if (this.type === "Base") {
        totalAmount = this.splitRate + splitDARate;
      } else {
        const legRatesTotal = this.legDetails.reduce(
          (total, item) => total + (Number(item.rate) || 0),
          0
        );
        totalAmount = legRatesTotal + splitDARate;
      }
      return totalAmount;
    },
  },
  data() {
    return {
      formErrors: {},
      splitRate: null,
      isValid: true,
      splitLegRateDetails: {},
    };
  },
  methods: {
    handleSplitRateInput() {
      this.$delete(this.formErrors, "splitRate");

      this.updateTotalRate();
    },
    updateTotalRate() {
      this.splitRate =
        this.daDetails.rate - (this.splitLegRateDetails.rate || 0);
    },
    totalRate() {
      return (
        this.legDetails.reduce((sum, leg) => sum + Number(leg.rate || 0), 0) +
        Number(this.splitLegRateDetails.rate || 0)
      );
    },

    gatherSplitRateData() {
      const splitDataRate = {
        splitRate: this.splitLegRateDetails.rate,
        legRates: this.legDetails.map((leg) => ({
          id: leg.id,
          rate: leg.rate,
        })),
      };
      this.$emit("splitRateData", splitDataRate);
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let data = {
        ...this.splitLegRateDetails,
      };
      data.adjustedRate = this.splitRate;

      this.$api.deliveryAdvice
        .splitSubHireLegs(this.daDetails.id, data)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "SubHire Leg Splitted successfully!",
            color: "success",
          });
          this.resetForm();
          this.$emit("refreshList");
          this.showSplitLegRateForm = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't split SubHire!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    resetForm() {
      this.splitLegRateDetails = {};
      (this.formErrors = {}), (this.legDetails = []);
      if (this.$refs.chargeForm) {
        this.$refs.chargeForm.reset();
      }
      this.$emit("refreshList");
    },
  },
};
</script>
