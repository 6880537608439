<template>
  <v-dialog width="500" v-model="SAPBonusRemarks" persistent>
    <v-card>
      <v-card-title class="secondary white--text">
        <span>Bonus  Remarks </span>

        <v-spacer></v-spacer>
        <v-btn icon @click="resetForm(), (SAPBonusRemarks = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-if="SAPBonusRemarks" v-model="isValid" ref="remarksForm">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Remarks *"
                :clearable="false"
                type="text"
                :rules="[(val) => !!val || 'Remarks is Required!']"
                hide-details="auto"
                v-model="formDetails.remarks"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    legBonus: {
      type: [Number, String],
    },

    startDate: {
      type: [String],
    },
    endDate: {
      type: [String],
    },
    legId: {
      type: [Array],
    },
    empId: {
      type: [Number, String],
    },
  },
  computed: {
    SAPBonusRemarks: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      module_type: null,
      isValid: true,
      formErrors: {},
      formDetails: {},
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.formDetails = {
          bonus: this.legBonus,
          updatelegId: this.legId,

          empIds: this.empId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else {
        this.formDetails = {};
      }
    },
  },
  methods: {
    resetForm() {
      if (this.$refs.remarksForm) {
        this.$refs.remarksForm.reset();
      }
      this.formDetails = {};
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        id: this.formDetails.updatelegId,
        bonus: this.formDetails.bonus,
        emp_id: this.formDetails.empIds,
        start_date: this.formDetails.startDate,
        end_date: this.formDetails.endDate,
        remarks:this.formDetails.remarks
      };

      this.$api.trip
        .deleteSapLegBonus(payload)
        .then((res) => {
          this.SAPBonusRemarks = false;
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Leg Bonus Deleted Successfully!",
            color: "success",
          });
          this.$emit("refreshList");
          this.formErrors = {};
        })
        .catch((err) => {
          this.formErrors = {};
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Bonus!",
            color: "error",
          });
        });
    },
  },
};
</script>
