<template>
  <div>
    <ListComponent
      title="SAP Payout List"
      :totalCount="totalItems"
      :backRoute="backRoute"
    >
      <template #list>
        <v-row class="ma-0">
          <v-col cols="3" class="px-0 pt-0 pb-2">
            <v-text-field
              label="Search"
              outlined
              dense
              append-icon="mdi-magnify"
              clearable
              hide-details="auto"
              v-model="filters.search"
              @input="searchPayout"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex justify-center">
            <span
              class="font-weight-bold mt-1 mr-1"
              style="font-size: 15px"
              v-if="selectedRowData.length > 0"
            >
              Selected Bonus:
            </span>

            <v-chip
              class="font-weight-bold"
              color="grey"
              label
              v-if="selectedRowData.length > 0"
              style="
                border-radius: 15px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
              "
            >
              <span
                class="mr-1"
                style="color: white"
                v-if="selectedRowData.length > 0"
              >
                {{ selectedTotalBonus }}
              </span>
              <span
                class="font-weight-bold"
                style="font-size: 12px; color: white"
                v-if="selectedRowData.length > 0"
              >
                {{ $globals.currency }}
              </span>
            </v-chip>

            <span
              class="font-weight-bold mt-1 mr-1 ml-3"
              style="font-size: 15px"
              >Total Bonus:</span
            >

            <v-chip
              class="font-weight-bold mr-2"
              color="green"
              label
              style="border-radius: 15px; display: flex; align-items: center"
            >
              <span class="mr-1" style="color: white">
                {{ totalBonus }}
              </span>
              <span
                class="font-weight-bold"
                style="font-size: 15px; color: white"
              >
                {{ $globals.currency }}
              </span>
            </v-chip>
          </v-col>
          <v-col
            v-if="totalItems > 0"
            cols="6"
            class="px-0 pt-0 pb-2 d-flex justify-end"
          >
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalItems"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>

          <v-col cols="12" class="pa-0">
            <AgGridVue
              style="width: 100%; height: calc(100vh - 172px)"
              class="ag-theme-alpine"
              id="myGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="sapDriverPayoutDataList"
              rowSelection="multiple"
              @selection-changed="onSelectionChanged"
            ></AgGridVue>
          </v-col>
        </v-row>
      </template>

      <template #dialogs>
        <SAPdriverBonusPayoutDialog
          v-model="showDriverPayoutDetails"
          :driverPayoutData="driverPayoutData"
          @refreshList="getSAPDriverPayoutsDataList()"
        />
      </template>

      <template #actions>
        <v-btn
          color="primary"
          depressed
          small
          @click="sentToSAP()"
          :disabled="selectedRowData.length === 0"
        >
          <v-icon>mdi-send</v-icon>
          <span>Send To SAP</span>
        </v-btn>
        <v-btn
          color="primary"
          small
          depressed
          @click="downloadSapBonusExcel()"
          class="ml-2"
        >
          <v-icon>mdi-download</v-icon>
          <span>Export Data</span>
        </v-btn>
      </template>
    </ListComponent>
  </div>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";

import BranchButton from "@/components/AgGridComponents/BranchButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import PayoutButton from "@/components/AgGridComponents/PayoutButton.vue";
import SAPdriverBonusPayoutDialog from "@/components/ModuleBased/dialogForms/SAPdriverBonusPayoutDialog.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    agColumnHeader: CustomHeader,
    AgGridVue,
    Pagination,
    SAPdriverBonusPayoutDialog,
    BranchButton,
    PayoutButton,
  },

  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Driver Name",
          field: "employee_name",
          sortable: true,
          headerCheckboxSelection: true,
          checkboxSelection: (params) => {
            return params.data.status !== "Approved";
          },
        },

        {
          headerName: "Total Bonus",
          field: "total_bonus",
          sortable: true,
        },
        {
          headerName: "Personnel No.",
          field: "personnel_no",
          sortable: true,
        },
        {
          headerName: "Depot",
          field: "depot",
          sortable: false,
        },
        {
          headerName: "Wage type.",
          field: "wage_type",
          sortable: true,
        },
        {
          headerName: "Currency",
          field: "currency",
          sortable: true,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
        },
        
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "PayoutButton",
          pinned: "right",
          width: 90,
        },
      ],

      sapDriverPayoutDataList: [],
      selectedSAPData: [],
      selectedRowData: [],
      showConfirmationDialogue: false,
      totalBonus: 0,
      title: "Confirmation",
      text: "Are you sure you want to Send Data to SAP ?",
      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},
      backRoute: "/app/admin/sap_bonus_payout/",

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      permissions: null,

      showDriverPayoutDetails: false,
      driverPayoutData: {},
    };
  },

  computed: {
    selectedTotalBonus() {
      const totalBonus = this.selectedRowData.reduce((total, row) => {
        return total + Number(row.total_bonus);
      }, 0);

      return Math.round(totalBonus);
    },
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    sendToSAPBonusData() {
      this.showConfirmationDialogue = true;
    },

    searchPayout() {
      this.pageNo = 1;
      this.getSAPDriverPayoutsDataList();
    },
    onSelectionChanged() {
      this.selectedRowData = this.gridApi.getSelectedRows();

      this.selectedSAPData = [];
      for (let i = 0; i < this.selectedRowData.length; i++) {
        this.selectedSAPData.push(this.selectedRowData[i].id);
      }
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSAPDriverPayoutsDataList();
    },
    prevPage() {
      this.pageNo--;
      this.getSAPDriverPayoutsDataList();
    },
    nextPage() {
      this.pageNo++;
      this.getSAPDriverPayoutsDataList();
    },
    viewDriverPayoutDetails(data) {
      this.driverPayoutData = data;
      this.showDriverPayoutDetails = true;
    },

    sentToSAP() {
      const config = {
        status: "secondary",
        title: "Send To SAP",
        text: "Are you sure you want to send to SAP?",
      };

      this.$confirm(config)
        .then(() => {
          this.$bus.$emit("showLoader", true);
          let sap_data_ids = [...this.selectedSAPData];

          this.$api.sapBonusPayout
            .bulkSendSAPBonusData(sap_data_ids)
            .then((resp) => {
              this.$bus.$emit("showLoader", false);
              this.$bus.$emit("showToastMessage", {
                message: "Sent To SAP!",
                color: "success",
              });
              this.getSAPDriverPayoutsDataList();
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        })
        .catch(() => {
          console.log("User clicked 'No'. Action was canceled.");
        });
    },

    async downloadSapBonusExcel() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        driver_bonus_processing: this.$route.params.id,
      };
      await this.$api.sapBonusPayout
        .downloadDAExcel(payload)
        .then(async (response) => {
          this.$bus.$emit("showLoader", false);
          var blob = response.data;

          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          downloadLink.download = "SAPData" + ".xlsx";
          downloadLink.click();
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.non_field_errors
                ? err.data.non_field_errors.join()
                : "Data not found"
            }`,
            color: "error",
          });

          this.$bus.$emit("showLoader", false);
        });
    },

    getSAPDriverPayoutsDataList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        driver_bonus_processing: this.$route.params.id,
        status: "Pending",
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.sapBonusPayout
        .getSAPDriverPayoutDataList(params)
        .then((res) => {
          this.sapDriverPayoutDataList = res.data;
          this.totalBonus = this.sapDriverPayoutDataList[0].total_bonus_sum;
          this.totalItems = res.count;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("order");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getSAPDriverPayoutsDataList();
      }
    }
  },
};
</script>
