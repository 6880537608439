var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"color":"primary"},on:{"click":_vm.viewItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}])},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.change &&
          _vm.params.data &&
          ['Created', 'Scheduled', 'Active'].indexOf(_vm.params.data.status) > -1
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 ml-1",attrs:{"color":"primary"},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary","disabled":!_vm.shouldShowCancelIcon},on:{"click":_vm.cancelDeliveryAdvice}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.cancelText))])]),_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.cancelText)+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.change &&
          _vm.params.data &&
          _vm.params.data.status == 'Invoice Generated' &&
          _vm.params.data.is_send_to_sap === 'No'
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.sendToSapDACostCharges}},'v-icon',attrs,false),on),[_vm._v(" mdi-send ")]):_vm._e()]}}])},[_c('span',[_vm._v("Send To SAP")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }