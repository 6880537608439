import verificationOtp from "@/pages/verification_otp/VerificationOtp.vue";


const VerificationRoutes = [
  {
    path: "verification-otp",
    name: "app-verification-otp",
    component: verificationOtp,
    children: [
      {
        path: "verification",
        redirect: "/app/verification-otp",
      },

     
    ],
  },
];
export default VerificationRoutes
