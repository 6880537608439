export const ReportType = [
  {
    text: "Customer Delivery Advice Summary Report",
    value: "Customer Delivery Advice Summary Report",
  },
  {
    text: "Delivery Advice Summary Report",
    value: "Delivery Advice Summary Report",
  },
  {
    text: "Delivery Advice Time Stamp Report",
    value: "Delivery Advice Time Stamp Report",
  },
  {
    text: "Delivery Advice List Report",
    value: "Delivery Advice List Report",
  },
  {
    text: "Customer Invoice Detailed Report",
    value: "Customer Invoice Detailed Report",
  },
  {
    text: "Invoice List (Consolidated with remarks)",
    value: "Invoice List",
  },
  {
    text: "DA Pending To Invoice Report",
    value: "DA Pending To Invoice Report",
  },
  {
    text: "Defective CheckList Report",
    value: "Defective CheckList Report",
  },
  {
    text: "Trip CheckList Log",
    value: "Trip CheckList Log",
  },
];
export const DAReplicateSampleDownloadHeaders = [
  [
    "Container No",
    "Container Weight",
    "Container No",
    "Container Weight",
    "Container No",
    "Container Weight",
    "Container No",
    "Container Weight",
  ],
];

export const ReportCategory = [
  {
    display_name: "Finance Reports",
    value: "Finance Reports",
  },
  {
    display_name: "Delivery Advice Reports",
    value: "Delivery Advice Reports",
  },
  {
    display_name: "Trip Reports",
    value: "Trip Report",
  },
  {
    display_name: "Vehicle Reports",
    value: "Vehicle Reports",
  },
  {
    display_name: "Driver Reports",
    value: "Driver Reports",
  },
  {
    display_name: "Driver Bonus Reports",
    value: "Driver Bonus Reports",
  },
  {
    display_name: "Sub Hire Report",
    value: "Sub Hire Reports",
  },
  {
    display_name: "Other Reports",
    value: "Other Reports",
  },
];

export const statusCategory = [
  {
    display_name: "Pending",
    value: "Pending",
  },
  {
    display_name: "Verified",
    value: "Verified",
  },
  {
    display_name: "Approved",
    value: "Approved",
  },
  {
    display_name: "Rejected",
    value: "Rejected",
  },
];

export const serviceTypeListCategory = [
  {
    display_name: "Local",
    value: "Local",
  },
  {
    display_name: "Cross Border",
    value: "Cross Border",
  },
];
export const IncidentType = [
  {
    display_name: "Parking",
    value: "Parking",
  },
  {
    display_name: "Speeding",
    value: "Speeding",
  },
];

export const CausedType = [
  {
    display_name: "Employee",
    value: "Employee",
  },
  {
    display_name: "Third Party",
    value: "Third Party",
  },
];

export const ClassificationType = [
  {
    display_name: "Minor",
    value: "Minor",
  },
  {
    display_name: "Major",
    value: "Major",
  },
  {
    display_name: "Other",
    value: "Other",
  },
];

export const StatusType = [
  {
    display_name: "Open",
    value: "Open",
  },
  {
    display_name: "Closed",
    value: "Closed",
  },
];

export const FinanceReportList = [
  {
    display_name: "Invoice Da Detail Report",
    value: "Invoice Da Detail Report",
  },
  {
    display_name: "Customer Invoice Detailed Report",
    value: "Customer Invoice Detailed Report",
  },
  {
    display_name: "Invoice List",
    value: "Invoice List",
  },
  {
    display_name: "DA Pending To Invoice Report",
    value: "DA Pending To Invoice Report",
  },
  {
    display_name: "Payment Receipt Report",
    value: "Payment Receipt Report",
  },
  {
    display_name: "Credit Note Detailed Report",
    value: "Credit Note Detailed Report",
  },
];

export const RequestStatusType = [
  {
    display_name: "Pending",
    value: "Pending",
  },
  {
    display_name: "Verified",
    value: "Verified",
  },
  {
    display_name: "Approved",
    value: "Approved",
  },
];


export const serviceTypeList = [
  {
    display_name: "Local",
    value: "Local",
  },
  {
    display_name: "Cross Border",
    value: "Cross Border",
  },

];
