<template>
  <div>
    <ListComponent title="Vehicle Cost" :totalCount="totalItems">
      <template #list>
        <v-row class="ma-0">
          <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
            <v-text-field
              label="Search"
              name="vehicle-list-search"
              outlined
              dense
              append-icon="mdi-magnify"
              clearable
              hide-details="auto"
              v-model="filters.search"
              @input="searchAdminVehicles"
            ></v-text-field>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  depressed
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="showFilterDialog = true"
                  class="mx-2"
                >
                  <v-badge
                    dot
                    color="primary"
                    :value="Object.keys(filters).length"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-badge>
                </v-btn>
              </template>

              <span v-if="Object.keys(filters).length == 0">Filters</span>

              <v-row v-else no-gutters>
                <v-col cols="12" class="font-weight-bold"
                  >Applied Filters</v-col
                >
                <v-col cols="12">
                  <span
                    v-for="(value, key) in filters"
                    :key="key"
                    class="text-capitalize"
                    >{{ key.replace("_", " ") }}
                    <br />
                  </span>
                </v-col>
              </v-row>
            </v-tooltip>

            <Columns :headers="headers" :gridColInstance="gridColumnApi" />
          </v-col>

          <v-col
            v-if="totalItems > 0"
            cols="8"
            class="px-0 pt-0 pb-2 d-flex justify-end"
          >
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalItems"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>

          <v-col cols="12" class="pa-0">
            <AgGridVue
              style="width: 100%; height: calc(100vh - 172px)"
              class="ag-theme-alpine"
              id="adminVehicleList"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              @columnMoved="resizeGrid"
              @columnVisible="resizeGrid"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="allVehicleCostList"
            />
          </v-col>
        </v-row>

        <AdminVehicleFilters
          v-model="showFilterDialog"
          :bindingObject="filters"
          @resetFilters="resetFilters"
          @applyFilters="itemsPerPageChanged(20)"
        />
      </template>

      <template #dialogs>
        <BulkUpload
          v-model="vehicleCostBulkUploadDialog"
          :uploadTo="uploadType"
          @refreshList="itemsPerPageChanged(10)"
        />
      </template>

      <template #actions>
        <v-btn
          v-if="permissions && permissions.add"
          @click="openVehicleCostBulkUpload('vehicle_cost')"
          name="vehicle-list-bulk-upload_cost"
          class="ml-3"
          color="primary"
          depressed
        >
          <v-icon>mdi-plus</v-icon>
          <span>Bulk Upload Vehicle Cost</span>
        </v-btn>
      </template>
    </ListComponent>
  </div>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import VehicleButton from "@/components/AgGridComponents/VehicleButton.vue";
import TripGoToButton from "@/components/AgGridComponents/TripGoToButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminVehicleFilters from "@/components/ModuleBased/dialogs/filters/AdminVehicleFilters.vue";
import VehicleCostButton from "@/components/AgGridComponents/VehicleCostButton.vue";

import BulkUpload from "@/components/General/BulkUpload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    VehicleButton,
    VehicleCostButton,
    agColumnHeader: CustomHeader,
    TripGoToButton,

    AdminVehicleFilters,

    BulkUpload,
  },

  data() {
    return {
      sorting: {},
      incidentLogsData: {},
      selectedVehicleId: null,
      editMode: false,
      headers: [
        {
          headerName: "Vehicle Number",
          field: "vehicle_number",
          sortable: true,
          minWidth: 130,
        },
        {
          headerName: "Pk No.",
          field: "pk_no",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Date",
          field: "date",
          sortable: true,
          minWidth: 120,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Gallons",
          field: "gallons",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Kilometers",
          field: "kilometers",
          sortable: true,
          tooltipField: "kilometers",
          minWidth: 300,
        },
        {
          headerName: "Per Gallon Cost",
          field: "per_gallon_cost",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Remarks",
          field: "remarks",
          sortable: true,
          minWidth: 85,
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "VehicleCostButton",
          pinned: "right",
          minWidth: 150,
        },
      ],

      // details vars
      showIncidentDialogForm: false,
      selectedVehicleId: null,
      detailsDrawer: false,

      allVehicleCostList: [],

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,

      // bulk create
      uploadType: "driver",
      vehicleBulkUploadDialog: false,
      vehicleCostBulkUploadDialog: false,

      permissions: null,

      // Filter vars
      filters: {},
      showFilterDialog: false,

      // bulk vars
      showBulkDialog: false,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    openVehicleCostBulkUpload(type) {
      this.uploadType = type;
      this.vehicleCostBulkUploadDialog = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchAdminVehicles() {
      this.pageNo = 1;
      this.getVehicleCostList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getVehicleCostList();
    },
    prevPage() {
      this.pageNo--;
      this.getVehicleCostList();
    },
    nextPage() {
      this.pageNo++;
      this.getVehicleCostList();
    },
    checkFilters() {
      const filters = localStorage.getItem("vehicle_filters");
      const user_data = JSON.parse(localStorage.getItem("userProfile"));

      if (filters) {
        this.filters = JSON.parse(filters);
        if (this.filters.sorting) {
          this.sorting = { ...this.filters.sorting };
          this.headers.map((obj) => {
            if (obj.field in this.sorting) {
              if (this.sorting[obj.field] == false) {
                obj.sort = "desc";
              } else if (this.sorting[obj.field]) {
                obj.sort = "asc";
              }
            }
          });
        }
      }
    },
    setFilters() {
      if (
        this.filters &&
        Object.keys(this.filters).length > 0 &&
        Object.keys(this.filters).indexOf("ordering")
      ) {
        delete this.filters.ordering;
      }
      localStorage.setItem(
        "vehicle_filters",
        JSON.stringify({ ...this.filters, sorting: this.sorting })
      );
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    getVehicleCostList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        company: this.currentProfile.company,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.setFilters();
      this.$api.vehicleCost
        .getVehicleCostBulkList(params)
        .then((res) => {
          this.allVehicleCostList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    viewVehicle(id) {
      this.selectedVehicleId = id;
      this.detailsDrawer = true;
    },

    deleteVehicleCost(id) {
      const config = {
        status: "secondary",
        title: "Delete",
        text: "Are you sure you want to delete?",
      };

      this.$confirm(config)
        .then(() => {
          this.$bus.$emit("showLoader", true);

          this.$api.vehicleCost
            .deleteVehicleCostData(id)
            .then((resp) => {
              this.$bus.$emit("showLoader", false);
              this.$bus.$emit("showToastMessage", {
                message: "Vehicle Cost Data Deleted successfully!",
                color: "success",
              });
              this.getVehicleCostList();
            })
            .catch((err) => {
              console.error(err);
              this.$bus.$emit("showLoader", false);
            });
        })
        .catch(() => {
          console.log("User clicked 'No'. Deletion was canceled.");
        });
    },
  },

  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("vehicle");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.checkFilters();
        this.getVehicleCostList();
      }
    }
  },
};
</script>
