<template>
  <v-dialog max-width="50%" v-model="showManageRateDialog" persistent>
    <v-card class="rounded-lg ma-0 elevation-8">
      <!-- Card Title -->
      <v-card-title class="secondary white--text px-2 py-2">
        <span class="font-weight-bold" style="font-size: 20px">
          Manage Rate
        </span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="resetForm(), (showManageRateDialog = false)"
          color="grey lighten-3"
          class="hover--elevate-4"
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card variant="outlined">
        <v-row class="ma-0">
          <v-col cols="4" class="d-flex justify-start align-center">
            <span class="mr-2 mt-1 pl-3">Delivery Advice No.</span>
          </v-col>
          <v-col cols="5" class="d-flex justify-center align-center">
            <span class="mr-2 mt-1">Payment Terms</span>
          </v-col>
          <v-col cols="3" class="d-flex justify-end align-center pr-14">
            <span class="mr-2 mt-1">DA Base Rate</span>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col cols="4" class="d-flex justify-start align-center">
            <span class="mr-2 mt-0 pl-3 font-weight-bold">{{
              daDetails ? daDetails.da_no : ""
            }}</span>
          </v-col>
          <v-col cols="5" class="d-flex justify-center align-center">
            <span class="mr-2 mt-0 pr-3 font-weight-bold">{{
              daDetails ? daDetails.payment_term : ""
            }}</span>
          </v-col>
          <v-col cols="3" class="d-flex justify-end pr-10">
            <v-chip
              class="font-weight-bold pl-5"
              color="grey"
              label
              style="border-radius: 15px; display: flex; align-items: center"
            >
              <span class="mr-1" style="color: white">{{
                daDetails.rate
              }}</span>
              <span
                class="font-weight-bold"
                style="font-size: 15px; color: white"
                >{{ $globals.currency }}</span
              >
            </v-chip>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="ma-0 rounded-lg" variant="outlined">
        <v-form ref="chargeForm" v-if="showManageRateDialog" v-model="isValid">
          <v-row class="ma-1">
            <v-col cols="9" lg="10" class="Form-Columns">
              <span
                class="font-weight-medium text-secondary ml-0"
                style="font-size: 20px; margin-bottom: 8px"
                >Edit DA Rate:</span
              >
            </v-col>

            <v-col cols="3" lg="2" class="Form-Columns pl-3 pr-6">
              <InputField
                type="number"
                :label="`DA Rate (${$globals.currency}) *`"
                required
                hide-details="auto"
                v-model="daBaseRate"
                :error-messages="formErrors.baseRate"
                :rules="[
                  (val) => !!val || 'DA Rate is required!',
                  (val) => Number(val) >= 0 || 'Rate cannot be negative!',
                ]"
                @input="onDaBaseRateChange"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <!-- Leg Rate Validation -->
          <v-row class="ma-0 mt-4">
            <v-col cols="12">
              <v-row
                class="ma-0"
                style="background-color: #e0e0e0; border-radius: 3px"
              >
                <v-col cols="3" class="py-1 d-flex justify-left">
                  <span class="font-weight-medium text-secondary"> Leg No</span>
                </v-col>
                <v-col cols="7" class="py-1 d-flex justify-center">
                  <span class="font-weight-medium text-secondary"> Lane</span>
                </v-col>
                <v-col cols="2" class="py-1 d-flex justify-end">
                  <span class="font-weight-medium text-secondary pr-12">
                    Rate</span
                  >
                </v-col>
              </v-row>

              <v-row
                class="d-flex justify-end align-center ma-2"
                v-for="(item, i) in legDetailsData"
                :key="i"
              >
                <v-col cols="3" class="py-1 d-flex justify-left">
                  {{ item.leg_id }}
                </v-col>
                <v-col cols="7" class="py-1 d-flex justify-center">
                  {{ item.leg_lane_title }}
                </v-col>
                <v-col cols="2" class="py-1 d-flex justify-end pl-5">
                  <InputField
                    type="number"
                    v-model="item.rate"
                    label="Leg Rate"
                    :error-messages="formErrors[`rate_${i}`]"
                    @input="updateTotalRate"
                    hide-details="auto"
                    required
                    :rules="[
                      (val) => !!val || 'Leg Rate is required!',
                      (val) => Number(val) >= 0 || 'Rate cannot be negative!',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!-- Display the Total Leg Rate below -->
              <v-row class="mt-3">
                <v-col cols="11" class="d-flex justify-end ml-4">
                  <span class="mr-2 font-weight-bold mt-1">Total Amount:</span>
                  <v-chip
                    class="font-weight-bold"
                    color="success"
                    label
                    style="
                      border-radius: 15px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span class="mr-1"> {{ totalLegRate }} </span>
                    <span class="font-weight-bold" style="font-size: 15px">
                      {{ $globals.currency }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-card-text class="mb-0">
            <v-row v-if="isLegRateNotEqualBaseRate" class="mt-3">
              <v-col cols="12">
                <v-alert
                  border="left"
                  color="red"
                  dense
                  text
                  dismissible
                  type="success"
                >
                Please ensure that the Total Leg Rate is equal to the DA Base Rate!</v-alert
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>

      <v-divider class="my-2"></v-divider>

      <v-card-actions class="justify-end" style="padding: 10px">
        <v-btn
          color="primary"
          depressed
          class="elevation-5"
          :disabled="isSubmitButtonDisabled"
          @click="submitForm()"
          style="
            transition: 0.3s ease-in-out;
            margin-right: 10px;
            padding: 8px 16px;
          "
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  data() {
    return {
      daBaseRate: null, // To store DA Base Rate value
      totalLegRate: 0, // To store the total leg rate
      legDetailsData: [],
      formErrors: {},
      isValid: true,
      isLegRateNotEqualBaseRate: false, // Boolean to check if leg rates sum to DA base rate
      isSubmitButtonDisabled: false, // To disable the submit button if conditions are not met
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Base",
    },
    legDetails: {
      type: Array,
      default: () => [],
    },
    daDetails: {
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.legDetailsData = this.legDetails;
        this.daBaseRate = parseFloat(this.daDetails.rate).toFixed(2);

        this.resetErrorsAndState();
        this.updateTotalRate();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showManageRateDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onDaBaseRateChange() {
      this.updateTotalRate();
      this.checkIfRatesMatch();
    },
    resetErrorsAndState() {
      this.isLegRateNotEqualBaseRate = false;
      this.isSubmitButtonDisabled = false;
    },

    updateTotalRate() {
      this.totalLegRate = this.legDetailsData.reduce((sum, leg) => {
        return sum + (parseFloat(leg.rate) || 0);
      }, 0);

      this.checkIfRatesMatch();
    },

    checkIfRatesMatch() {
      this.isLegRateNotEqualBaseRate =
        parseFloat(this.totalLegRate) !== parseFloat(this.daBaseRate);

      this.isSubmitButtonDisabled = this.isLegRateNotEqualBaseRate;

      // if (this.isLegRateNotEqualBaseRate) {
      //   if (this.showManageRateDialog) {
      //     this.$bus.$emit("showToastMessage", {
      //       message: "Please ensure that the Total Leg Rate is equal to the DA Base Rate!",
      //       color: "error",
      //     });
      //   }
      // }
    },

    submitForm() {
      // Check if total leg rate equals the DA base rate
      if (this.isLegRateNotEqualBaseRate) {
        this.$bus.$emit("showToastMessage", {
          message: "Please ensure that the Total Leg Rate is equal to the DA Base Rate!",
          color: "error",
        });
        return;
      }

      // Submit the form if validation is successful
      this.$bus.$emit("showLoader", true);
      this.formErrors = {};

      let data = {
        daBaseRate: this.daBaseRate, // Include DA Base Rate
        legRates: this.legDetailsData.map((leg) => ({
          leg_id: leg.leg_id,
          rate: leg.rate,
        })),
      };

      this.$api.deliveryAdvice
        .updateManageRate(this.daDetails.id, data)
        .then(() => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "SubHire Leg Splitted successfully!",
            color: "success",
          });
          this.resetForm();
          this.$emit("refreshList");
          this.showManageRateDialog = false;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't split SubHire!",
            color: "error",
          });
          this.formErrors = err.data;
        });
    },

    resetForm() {
      this.formErrors = {};
      this.legDetailsData = [];
      this.daBaseRate = null;
      this.totalLegRate = 0;
      this.isLegRateNotEqualBaseRate = false;
      this.isSubmitButtonDisabled = false;
      if (this.$refs.chargeForm) {
        this.$refs.chargeForm.reset();
      }
      this.$emit("refreshList");
    },
  },
};
</script>
