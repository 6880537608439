<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          color="error"
          :name="`vehicle-list-edit-${params?.rowIndex}`"
          class="ml-1 mr-2"
          v-on="on"
          v-bind="attrs"
          @click="deleteItem"
          >mdi-delete</v-icon
        >
      </template>
      <span>Delete</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    deleteItem() {
      this.params.context.parentComponent.deleteVehicleCost(this.params.data.id);
    },
   
  },
};
</script>
