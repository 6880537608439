

import VehicleCostList from "@/pages/admin/asset-management/vehicle_cost/VehicleCostList.vue";

const vehicleRoutes = [
  {
    path: "vehicle_cost",
    name: "app-admin-asset-management-vehicle-cost",
    component: VehicleCostList,
    // children: [
    //   {
    //     path: "list",
    //     name: "app-admin-asset-management-asset-cost-list",
    //     component: VehicleCostList,
    //   },
    // ],
  },
];

export default vehicleRoutes;
