import { user } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getUserPreferences(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(user.userPreferencesBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  changePassword(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(user.changePasswordBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserStatistics(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${user.userBase}user_statistics/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  setUserPreferences(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(user.userBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getUserList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(user.userBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyRequestLists(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(user.companyRequestBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCompanyRequestStatistics(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${user.companyRequestBase}request_statistics/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCompanyFormRequest(payload = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${user.companyRequestBase}approve_and_create_company/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${user.userBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${user.userBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addUser(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(user.userBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateUser(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${user.userBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateUser(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${user.userBase}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
