var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.params &&
          _vm.params.context &&
          _vm.params.context.parentComponent &&
          _vm.params.context.parentComponent.permissions &&
          _vm.params.context.parentComponent.permissions.change
        )?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mr-2",attrs:{"color":"primary","name":`vehicle-list-edit-${_vm.params?.rowIndex}`},on:{"click":_vm.editItem}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")]):_vm._e()]}}])},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"name":`vehicle-list-view-${_vm.params?.rowIndex}`,"color":"primary"},on:{"click":_vm.viewItem}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}])},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"name":`vehicle-incident-view-${_vm.params?.rowIndex}`,"color":"primary"},on:{"click":_vm.incidentLogs}},'v-icon',attrs,false),on),[_vm._v("mdi-clipboard-text")])]}}])},[_c('span',[_vm._v("Incident Log")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }