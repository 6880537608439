<template>
  <v-dialog max-width="100%" v-model="showIncidentFormDialog">
    <v-card>
      <v-card-title class="secondary white--text py-2 px-4">
        <span v-if="editMode" class="text-capitalize"> Edit {{ module }} </span>

        <span v-else class="text-capitalize"> Add {{ module }} </span>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="resetForm(), $emit('closeMaintenanceForm')"
          name="vehicle-maintenance-close"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="incidentForm"
          v-if="showIncidentFormDialog"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                clearable
                :items="allVehicleList"
                label="Vehicle Number"
                itemText="vehicle_number"
                itemValue="id"
                readonly
                :rules="[(val) => !!val || 'Vehicle is Required!']"
                hide-details="auto"
                v-model="vehicleId"
                :error-messages="formErrors.vehicle"
                @input="delete formErrors.vehicle"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                clearable
                :items="allDriverList"
                label="Driver"
                itemText="driver_name"
                itemValue="id"
                hide-details="auto"
                :rules="[(val) => !!val || 'Incident Type Required!']"
                v-model="incidentFormDetails.driver"
                :error-messages="formErrors.driver"
                @input="delete formErrors.driver"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                clearable
                :items="allTrailerList"
                label="Trailer"
                itemText="trailer_number"
                itemValue="id"
                hide-details="auto"
                v-model="incidentFormDetails.trailer"
                :error-messages="formErrors.trailer"
                @input="delete formErrors.trailer"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="text"
                v-model="incidentFormDetails.location"
                label="Location"
                hide-details="auto"
                :error-messages="formErrors.location"
                @input="delete formErrors.location"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                label="Incident Type *"
                name="incident-type-vehicle"
                :items="IncidentCategory"
                itemText="display_name"
                itemValue="value"
                :rules="[(val) => !!val || 'Incident Type Required!']"
                hide-details="auto"
                v-model="incidentFormDetails.incident_type"
                :error-messages="formErrors.incident_type"
                @input="delete formErrors.incident_type"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Incident Date *',
                  name: 'vehicle-incident-date',
                  clearable: true,
                  rules: [(val) => !!val || 'Incident Date is Required!'],
                  'hide-details': 'auto',
                  'error-messages': formErrors.incident_date,
                }"
                required
                :min="today"
                v-model="incidentFormDetails.incident_date"
                @input="delete formErrors.incident_date"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                label="Caused  By *"
                name="vehicle-caused-type-category"
                :items="CausedTypeCategory"
                itemText="display_name"
                itemValue="value"
                :rules="[(val) => !!val || 'Caused Type is Required!']"
                hide-details="auto"
                v-model="incidentFormDetails.caused_by"
                :error-messages="formErrors.caused_by"
                @input="delete formErrors.caused_by"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                v-model="incidentFormDetails.fine_amount"
                label="Fine Amount"
                hide-details="auto"
                :error-messages="formErrors.fine_amount"
                @input="delete formErrors.fine_amount"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                v-model="incidentFormDetails.black_points"
                label="Black Points"
                hide-details="auto"
                :error-messages="formErrors.black_points"
                @input="delete formErrors.black_points"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                label="Classification *"
                name="vehicle-classification-type"
                :items="ClassificationTypeCategory"
                itemText="display_name"
                itemValue="value"
                :rules="[(val) => !!val || 'Classification Type is Required!']"
                hide-details="auto"
                v-model="incidentFormDetails.classification"
                :error-messages="formErrors.classification"
                @input="delete formErrors.classification"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                v-model="incidentFormDetails.claim_number"
                label="Claim Number"
                hide-details="auto"
                :error-messages="formErrors.claim_number"
                @input="delete formErrors.claim_number"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="text"
                v-model="incidentFormDetails.claim_remarks"
                label="Claim Remarks"
                hide-details="auto"
                :error-messages="formErrors.claim_remarks"
                @input="delete formErrors.claim_remarks"
              />
            </v-col>

            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                v-model="incidentFormDetails.company_paid_amount"
                label="Company Paid Amount"
                hide-details="auto"
                :error-messages="formErrors.company_paid_amount"
                @input="delete formErrors.company_paid_amount"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <InputField
                type="number"
                v-model="incidentFormDetails.employee_deduction"
                label="Employee Deduction"
                hide-details="auto"
                :error-messages="formErrors.employee_deduction"
                @input="delete formErrors.employee_deduction"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns">
              <MultiSelect
                label="Status *"
                name="vehicle-status"
                :items="StatusTypeCategory"
                :rules="[(val) => !!val || 'Status is Required!']"
                hide-details="auto"
                itemText="display_name"
                itemValue="value"
                v-model="incidentFormDetails.status"
                :error-messages="formErrors.status"
                @input="delete formErrors.status"
              />
            </v-col>
            <v-col cols="12" lg="12" class="Form-Columns">
              <TextareaField
                label="Description "
                name="vehicle-incident-description"
                required
                hide-details="auto"
                v-model="incidentFormDetails.description"
                :error-messages="formErrors.description"
                @input="delete formErrors.description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="!editMode"
          name="vehicle-incident-reset"
          class="mr-2"
          color="secondary"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
        <v-btn
          v-if="editMode"
          color="primary"
          name="vehicle-incident-submit"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Update
        </v-btn>
        <v-btn
          v-else
          color="primary"
          name="vehicle-incident-submit"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-3 pb-1 pl-1 pr-1">
            <AgGridVue
              style="width: 100%; height: calc(70vh - 270px)"
              class="small-table ag-theme-alpine"
              id="advancedSearchGrid"
              :columnDefs="headers"
              :context="context"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="incidentLogsList"
            ></AgGridVue>
          </v-col>

          <v-col
            v-if="totalIncidentList > 0"
            cols="12"
            class="d-flex justify-end py-0"
          >
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalIncidentList"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import IncidentLogButton from "@/components/AgGridComponents/IncidentLogButton.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import {
  IncidentType,
  CausedType,
  ClassificationType,
  StatusType,
} from "@/utils/choices";

export default {
  components: {
    DateField,
    MultiSelect,
    AgGridVue,
    IncidentLogButton,
    TextareaField,
    Pagination,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    incidentLogsData: {
      type: Object,
      default: () => {},
    },
    incidentVehicleId: {
      type: Number,
    },
  },
  computed: {
    vehicleId() {
      return this.incidentVehicleId ? this.incidentVehicleId : null;
    },
    showIncidentFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  data() {
    return {
      incidentFormDetails: {},
      allVehicleList: [],
      incidentLogsList: [],
      formErrors: {},
      IncidentCategory: IncidentType,
      CausedTypeCategory: CausedType,
      ClassificationTypeCategory: ClassificationType,
      StatusTypeCategory: StatusType,
      allTrailerList: [],
      vehicleIncidentId: null,

      allDriverList: [],
      totalIncidentList: 100,

      allIncidentList: [],
      isValid: true,
      isTextInput: false,
      pageNo: 1,
      itemsPerPage: 100,

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },

      today: new Date().toISOString().slice(0, 10),
      headers: [
        {
          headerName: "Driver Name",
          field: "driver_name",
          tooltipField: "depot",
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Location",
          field: "location",
          sortable: true,
          minWidth: 65,
          tooltipField: "location",
        },

        {
          headerName: "Incident type",
          field: "incident_type",
          sortable: true,
          minWidth: 65,
          tooltipField: "customer_name",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Caused By",
          field: "caused_by",
          sortable: true,
          tooltipField: "caused_by",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Description",
          field: "description",
          sortable: true,
          minWidth: 30,
          tooltipField: "description",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Fine Amount",
          field: "fine_amount",
          sortable: true,
          minWidth: 70,
        },
        {
          headerName: "Black Points",
          field: "black_points",
          sortable: true,
          tooltipField: "black_points",
          minWidth: 40,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },

        {
          headerName: "Classification",
          field: "classification",
          tooltipField: "classification",
          sortable: true,
          minWidth: 50,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Claim Number",
          field: "claim_number",
          sortable: true,
          minWidth: 80,
          tooltipField: "claim_number",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Claim Remarks",
          field: "claim_remarks",
          sortable: true,
          minWidth: 80,
          tooltipField: "claim_remarks",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Company Paid Amount",
          field: "company_paid_amount",
          sortable: true,
          minWidth: 80,
          tooltipField: "company_paid_amount",
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Employee Deduction",
          field: "employee_deduction",
          tooltipField: "employee_deduction",
          minWidth: 50,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Status",
          field: "status",
          tooltipField: "status",
          minWidth: 45,
          sortable: true,
          cellStyle: (params) => {
            return { backgroundColor: params.data.status_color };
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "IncidentLogButton",
          pinned: "right",
          minWidth: 150,
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.formErrors = {};
        if (this.editMode && this.incidentVehicleId != null) {
          this.getIncidentObject(this.incidentVehicleId);
        }

        this.getVehicleList();
        this.getTrailerList();
        this.getDriverList();
        this.getIncidentLogsList();
      }
    },
  },
  methods: {
    prevPage() {
      this.pageNo--;
      this.getLegsList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLegsList();
    },
    nextPage() {
      this.pageNo++;
      this.getLegsList();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },

    editIncidentVehicle(id) {
      this.editMode = true;
      this.$bus.$emit("showLoader", true);
      this.vehicleIncidentId = id;

      this.$api.incidentLogs
        .getIncidentObject(id)
        .then((res) => {
          this.incidentFormDetails = res.data;

          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          this.resetForm();
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDriverList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
        company: this.currentProfile.company,
      };

      this.$api.driver
        .getDriverList(params)
        .then((res) => {
          this.allDriverList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    getVehicleList() {
      this.$api.vehicle
        .getVehicleList({
          limit: "all",
          company: this.currentProfile.company,
        })
        .then((res) => {
          this.allVehicleList = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload;
      if (!this.editMode) {
        payload = {
          ...this.incidentFormDetails,
          vehicle: this.vehicleId,
        };

        this.$api.incidentLogs
          .addIncident(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Incident Logs created successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showIncidentFormDialog = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't create Incident Logs!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        payload = {
          id: this.vehicleIncidentId,
          data: this.incidentFormDetails,
        };
        this.$api.incidentLogs
          .updateIncident(payload)
          .then((res) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Incident Logs updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.resetForm();
            this.showIncidentFormDialog = false;
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Incident Logs!",
              color: "error",
            });
            console.error(err);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      if (this.$refs.incidentForm) {
        this.$refs.incidentForm.reset();
      }
      this.incidentFormDetails = {};
    },

    getTrailerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };

      this.$api.trailer
        .getTrailerList(params)
        .then((res) => {
          this.allTrailerList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getIncidentLogsList(params = {}) {
      this.$bus.$emit("showLoader", true);

      params = {
        offset: this.offset,
        vehicle: this.vehicleId,
        limit: "all",
        ...params,
      };

      this.$api.incidentLogs
        .getIncidentLogList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.incidentLogsList = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data.error;
          if (err && err.data) {
            const showToast = (message) => {
              this.$bus.$emit("showToastMessage", {
                message,
                color: "error",
                hasActions: true,
              });
            };

            if (err.data.message) {
              showToast(err.data.message);
            } else if (
              err.data.error &&
              err.data.error.non_field_errors &&
              err.data.error.non_field_errors.length
            ) {
              showToast(err.data.error.non_field_errors.join(", "));
            } else {
              showToast("Fetch DA Lits Failed");
            }
          }
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
