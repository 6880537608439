import axios from "axios";
import authHeader from "@/utils/authHeader";
import router from "@/router/index";

let config = {
  baseURL: process.env.VUE_APP_BASE_URL || "",
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (req) {
    const auth = authHeader();
    if (
      [
        "api/v2/login/",
        "api/v2/reset_password/",
        "api/v2/request_username/",
        "api/v2/reset_password_request/",
        "api/v2/company_request/mark_otp_verification/",
        "/api/v2/company_request/",
      ].indexOf(req.url) == -1
    ) {
      if (auth) {
        req.headers["Authorization"] = auth;
        return req;
      } else {
        throw new axios.Cancel();
      }
    } else {
      console.log("here");
      return req;
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    const status = error?.response?.status;
    if (status && status == 401) {
      const path = router?.currentRoute?.path;
      localStorage.clear();
      if (path && path != "/app/login") {
        router.push("/app/login");
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
