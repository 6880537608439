<template>
  <v-row class="ma-0">
    <v-col cols="2" class="pa-0">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change
            "
            class="ml-1 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="editItem"
          >
            {{
              params && params.data && params.data.is_send_to_sap
                ? "mdi-eye"
                : "mdi-pencil"
            }}
            mdi-eye
          </v-icon>
        </template>
        <span>{{
          params && params.data && params.data.is_send_to_sap ? "View" : "Edit"
        }}</span>
      </v-tooltip>
    </v-col>

    <v-col cols="2" class="pa-0">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change
            "
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="download"
          >
            mdi-download
          </v-icon>
        </template>
        <span>Download Payment Receipt</span>
      </v-tooltip>
    </v-col>

    <v-col cols="2" class="pa-0">
      <v-tooltip top v-if="params.data.delivery_advice_number">
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change
            "
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="downloadInvoice"
          >
            mdi-file-download-outline
          </v-icon>
        </template>
        <span>Download Invoice</span>
      </v-tooltip>
    </v-col>

    <v-col cols="2" class="pa-0">
      <v-tooltip top v-if="params.data.delivery_advice_number">
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.change &&
              params.data.is_send_to_sap === 'No'
            "
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            :disabled="params.data && parseInt(params.data.total_amount) == 0"
            v-bind="attrs"
            @click="sendToSap"
          >
            mdi-send
          </v-icon>
        </template>
        <span>Send To SAP</span>
      </v-tooltip>
    </v-col>

    <v-col cols="2" class="pa-0">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add
            "
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="copyItem"
            class="ml-2 mr-2"
          >
            mdi-content-copy
          </v-icon>
        </template>
        <span>Copy Payment Receipt</span>
      </v-tooltip>
    </v-col>

    <v-col cols="2" class="pa-0">
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewLogs"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Payment Receipt Logs</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    editItem() {
      this.params.context.parentComponent.editPaymentReceipt(
        this.params.data.id
      );
    },
    download() {
      this.params.context.parentComponent.getPaymentReceiptBlob(
        this.params.data.id,
        this.params.data.receipt_id
      );
    },
    downloadInvoice() {
      this.params.context.parentComponent.getPaymentReceiptBlob(
        this.params.data.id,
        this.params.data.receipt_id,
        true
      );
    },
    copyItem() {
      this.params.context.parentComponent.copyPaymentReceipt(
        this.params.data.id
      );
    },
    sendToSap() {
      this.params.context.parentComponent.paymentReceiptSendToSap(
        this.params.data.id
      );
    },
    viewLogs() {
      this.params.context.parentComponent.viewPaymentReceiptLogs(
        this.params.data.id
      );
    },
  },
};
</script>
