<template>
  <FilterParent v-model="showAdminUserFilters" module="Company Request ">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="RequestStatusType"
            itemText="display_name"
            itemValue="value"
            label="Select Status"
            clearable
            v-model="filterObject.status"
          />
        </v-col>

        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            hide-details="auto"
            :items="serviceTypeList"
            itemText="display_name"
            itemValue="value"
            label="Select Service Type"
            clearable
            v-model="filterObject.service_type"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        color="secondary"
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FilterParent from "@/components/General/FilterParent.vue";
import { RequestStatusType, serviceTypeList } from "@/utils/choices";

export default {
  components: {
    FilterParent,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customerGroupList: [],
      paymentTerms: [],
      RequestStatusType: RequestStatusType,
      serviceTypeList: serviceTypeList,
      booleanValues: [
        {
          text: "Yes",
          value: "true",
        },
        {
          text: "No",
          value: "false",
        },
      ],
    };
  },
  watch: {
    value(val) {
      if (val) {
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
  },
  methods: {},
};
</script>

<style></style>
