<template>
  <v-app>
    <v-container class="fill-height d-flex justify-center align-center">
      <v-card class="elevation-12 pa-6 rounded-lg" max-width="400">
        <!-- Logo -->
        <div class="text-center">
          <img
            src="@/assets/images/brand-logo.png"
            alt="Brand Logo"
            class="brand-logo mb-4"
          />
        </div>

        <!-- Title -->
        <v-card-title class="text-h5 font-weight-bold text-center">
          OTP Verification
        </v-card-title>

        <v-card-subtitle class="text-center text-grey-darken-1">
          Enter the 6-digit code sent to your Email.
        </v-card-subtitle>

        <!-- OTP Input -->
        <v-card-text>
          <v-otp-input
            v-model="otp"
            length="6"
            class="otp-input my-4"
            :disabled="isVerifying"
          ></v-otp-input>
          <v-alert v-if="errorMessage" type="error" dense class="mt-2">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions class="d-flex flex-column">
          <v-btn
            block
            color="primary"
            class="rounded-lg"
            :loading="isVerifying"
            :disabled="otp.length !== 6"
            @click="submitForm"
          >
            Verify OTP
          </v-btn>

          <v-progress-linear
            v-if="isResending"
            indeterminate
            color="primary"
            class="mt-2"
          ></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      otp: "",
      isVerifying: false,
      isResending: false,
      errorMessage: "",
    };
  },
  methods: {
    // submitForm() {
    //   this.$bus.$emit("showLoader", true);

    //   let payload = {
    //     otp: this.otp,
    //   };

    //   this.$api.unauthorisedApi
    //     .otpVerification(payload)
    //     .then((res) => {
    //       this.$bus.$emit("showLoader", false);

    //       this.$bus.$emit("showToastMessage", {
    //         message: "OTP Verification  SuccessFully",
    //         color: "success",
    //       });
    //       this.$router.push("/app/verification-otp/success");
    //     })
    //     .catch((err) => {
    //       this.$bus.$emit("showLoader", false);
    //       this.$bus.$emit("showToastMessage", {
    //         message: "Couldn't Verify!",
    //         color: "error",
    //       });
    //       this.formErrors = err.data;
    //     });
    // },

    submitForm() {
      this.$bus.$emit("showLoader", true);

      let payload = {
        otp: this.otp,
      };
      console.log("338",payload)

      this.$api.unauthorisedApi
        .otpVerification(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          console.log(res.data.status)

          if (res.data.status === 200) {
            this.$bus.$emit("showToastMessage", {
              message: "OTP Verification Successful",
              color: "success",
            });
            this.$router.push("/app/login");
          } else {
            this.$bus.$emit("showToastMessage", {
              message: "Invalid OTP, please try again.",
              color: "error",
            });
          }
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't Verify OTP!",
            color: "error",
          });
          this.errorMessage = err.response
            ? err.response.data
            : "Unknown error";
        });
    },
  },
};
</script>

<style scoped>
.brand-logo {
  width: 80px;
  height: auto;
}

.otp-input >>> input {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
</style>
