<template>
  <FormComponent
    title="SAP Bonus Payout"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions> </template>

    <template #formContent>
      <v-form v-model="isValid" ref="SAPForm">
        <v-card class="rounded-lg mb-3 mt-2 pb-3 ml-2 mr-2" variant="outlined">
          <v-card-title class="ml-1 pl-1 font-weight-bold">
            <v-row>
              <v-col cols="5"> Filter SAP Bonus Data </v-col>
              <v-col cols="7" class="d-flex justify-end">
                <v-btn
                  color="primary"
                  depressed
                  small
                  :disabled="!isValid"
                  @click="submitForm()"
                >
                  Submit
                </v-btn>

                <v-btn
                  color="secondary"
                  class="ml-3"
                  depressed
                  small
                  @click="resetForm()"
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <DateField
                ref="StartDateField"
                :inputProps="{
                  dense: true,
                  label: 'Trip Completion Start Date *',
                  clearable: true,
                  rules: [
                    (val) => !!val || 'Trip Completion Start Date is Required!',
                  ],
                  'hide-details': 'auto',
                  'error-messages': formErrors.start_date,
                }"
                v-model="SapBonusDetails.start_date"
                @change="delete formErrors.start_date, checkDates()"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <DateField
                ref="EndDateField"
                :inputProps="{
                  dense: true,
                  label: 'Trip Completion End Date *',
                  clearable: true,
                  rules: [
                    (val) => !!val || 'Trip Completion End Date is Required!',
                  ],
                  'hide-details': 'auto',
                  'error-messages': formErrors.end_date,
                }"
                :min="SapBonusDetails.start_date"
                :max="lastEndDate ? lastEndDate : null"
                v-model="SapBonusDetails.end_date"
                @change="delete formErrors.end_date"
              />
            </v-col>
            <v-col cols="12" lg="4" class="Form-Columns-Dense">
              <MultiSelect
                label="Depot "
                name="sap-bonus-form-depot"
                :returnObject="false"
                :items="allDepot"
                itemText="depot_name"
                itemValue="id"
                hide-details="auto"
                v-model="SapBonusDetails.depot"
                :error-messages="formErrors.depot"
                @input="delete formErrors.depot"
              ></MultiSelect>
            </v-col>
          </v-row>
        </v-card>

        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" class="pt-3 pb-1 pl-1 pr-1">
              <AgGridVue
                style="width: 100%; height: calc(90vh - 270px)"
                class="small-table ag-theme-alpine"
                id="advancedSearchGrid"
                :columnDefs="headers"
                :context="context"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :grid-options="gridOptions"
                :suppressDragLeaveHidesColumns="true"
                :rowData="driverSapBonusList"
              ></AgGridVue>
            </v-col>

            <v-col
              v-if="totalItems > 0"
              cols="12"
              class="d-flex justify-end py-0"
            >
              <Pagination
                :pageNo="pageNo"
                :totalItems="totalItems"
                :pageSize="itemsPerPage"
                @itemsPerPageChange="itemsPerPageChanged"
                @prevPage="prevPage"
                @nextPage="nextPage"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <SapApproveListDialog
        v-model="showApproveDataDialog"
        :ID="bonusID"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>
  </FormComponent>
</template>

<script>
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import SAPDriverProcessButton from "@/components/AgGridComponents/SAPDriverProcessButton.vue";
import SapApproveListDialog from "@/components/ModuleBased/dialogForms/SapApproveListDialog.vue";

import InputField from "@/components/FormBaseComponents/InputField.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    InputField,
    FormComponent,
    MultiSelect,
    SapApproveListDialog,
    DateField,
    AgGridVue,
    Pagination,
    SAPDriverProcessButton,
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      allDepot: [],
      driverSapBonusList: [],

      isValid: true,
      formErrors: {},
      bonusID: null,
      totalItems: 0,
      lastEndDate: null,
      showApproveDataDialog: false,
      SapBonusDetails: {},
      today: new Date().toISOString().slice(0, 16),
      backRoute: "/app/admin/sap_bonus_payout/",
      headers: [
        {
          headerName: "Payout ID",
          field: "payout_id",
          sortable: true,
          minWidth: 60,
          tooltipField: "payout_id",
          valueGetter: (params) => params.data?.payout_id || "N/A",
        },
        {
          headerName: "Trip Completion Start Date",
          field: "start_date",
          tooltipField: "start_date",
          sortable: true,
          minWidth: 60,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Trip Completion End Date",
          field: "end_date",
          sortable: true,
          minWidth: 60,
          tooltipField: "end_date",
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Depot",
          field: "depot_name",
          sortable: true,
          minWidth: 60,
          tooltipField: "depot_name",
          valueGetter: (params) => params.data?.depot_name || "N/A",
        },
        {
          headerName: "Driver Bonus Count",
          field: "total_records",
          sortable: true,
          minWidth: 60,
          tooltipField: "total_records",
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "SAPDriverProcessButton",
          pinned: "right",
          minWidth: 50,
        },
      ],

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      pageNo: 1,
      itemsPerPage: 20,
    };
  },
  methods: {
    showApproveData(id) {
      console.log("hello 93993", id);
      this.bonusID = id;
      this.showApproveDataDialog = true;
    },
    prevPage() {
      this.pageNo--;
      this.getSAPDriversProcessingList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getSAPDriversProcessingList();
    },
    nextPage() {
      this.pageNo++;
      this.getSAPDriversProcessingList();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    checkDates() {
      let currentDate = new Date(this.SapBonusDetails.start_date);
      let endDate = currentDate;
      endDate.setDate(currentDate.getDate() + 30);
      this.lastEndDate = endDate.toISOString().slice(0, 10);
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);

      this.SapBonusDetails = {
        ...this.SapBonusDetails,
      };
      this.$api.sapBonusPayout
        .addSAPDriverProcessData(this.SapBonusDetails)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Driver Bonus Processing  added successfully!",
            color: "success",
          });
          this.resetForm();
          this.getSAPDriversProcessingList();
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          console.log("33", err);
          this.$bus.$emit("showToastMessage", {
            message: `${
              err.data && err.data.message
                ? err.data.message
                : "Couldn't add SAP Bonus  Data"
            }`,
            color: "error",
          });
          this.formErrors = err.data;
        });
    },
    resetForm() {
      if (this.$refs.SAPForm) {
        this.$refs.SAPForm.reset();
      }
      this.SapBonusDetails = {};
    },
    viewPayout(id) {
      this.$router.push({ name: "app-admin-payout-list", params: { id: id } });
    },

    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getSAPDriversProcessingList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: this.itemsPerPage,
        offset: this.offset,
        ...params,
      };

      this.$api.sapBonusPayout
        .getSAPDriverProcessingList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.driverSapBonusList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  mounted() {
    this.getDepotList();
    this.getSAPDriversProcessingList();
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("order");
      if (!permissions || !permissions.add) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      }
    }
  },
};
</script>
