<template>
  <v-dialog width="500" v-model="SAPBonusUpdate"  persistent>
    <v-card>
      <v-card-title class="secondary white--text">
        <span> Update Bonus </span>

        <v-spacer></v-spacer>
        <v-btn icon @click="SAPBonusUpdate = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-2">
        <v-form v-if="SAPBonusUpdate" v-model="isValid">
          <v-row class="ma-0">
            <v-col cols="12" class="Form-Columns">
              <InputField
                label="Bonus Rate *"
                :clearable="false"
                type="number"
                hide-details="auto"
                v-model="formDetails.bonus"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    legBonus: {
      type: [Number, String],
    },
    tripId: {
      type: [Number, String],
    },
    startDate: {
      type: [String],
    },
    endDate: {
      type: [String],
    },
    legId: {
      type: [Array],
    },
    empId: {
      type: [Number, String],
    },
    payoutId: {
      type: [Number, String],
    },
  },
  computed: {
    SAPBonusUpdate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      module_type: null,
      isValid: true,
      formErrors: {},
      formDetails: {},
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.formDetails = {
          bonus: this.legBonus,
          updatelegId: this.legId,
          payoutIds: this.payoutId,
          empIds: this.empId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else {
        this.formDetails = {};
      }
    },
  },
  methods: {
    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (this.tripId) {
        let payload = {
          id: this.formDetails.updatelegId,
          bonus: this.formDetails.bonus,
          emp_id: this.formDetails.empIds,
          start_date: this.formDetails.startDate,
          end_date: this.formDetails.endDate,
        };

        this.$api.trip
          .bulkUpdateLegbonus(payload)
          .then((res) => {
            this.SAPBonusUpdate = false;
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Leg Bonus Updated Successfully!",
              color: "success",
            });
            this.$emit("refreshList");
            this.formErrors = {};
          })
          .catch((err) => {
            this.formErrors = {};
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Bonus!",
              color: "error",
            });
          });
      } else {
        let payload = {
          id: this.formDetails.payoutIds,
          bonus: this.formDetails.bonus,
          emp_id: this.formDetails.empIds,
        };

        this.$api.sapBonusPayout
          .UpdateSAPbonus(payload)
          .then((res) => {
            this.SAPBonusUpdate = false;
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Lease Bonus Updated Successfully!",
              color: "success",
            });
            this.formErrors = {};
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.formErrors = {};
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update Bonus!",
              color: "error",
            });
          });
      }
    },
  },
};
</script>
