<template>
  <v-dialog persistent v-model="showConfirmationDialogue" width="500">
    <v-card>
      <v-card-title class="py-1" :class="status"></v-card-title>
      <v-card-text class="pa-3">
        <v-row class="ma-0">
          <v-col cols="12" lg="2">
            <v-icon size="56" :color="status">{{ icon }}</v-icon>
          </v-col>
          <v-col cols="12" lg="10">
            <p class="text-h6 font-weight-medium mb-2">
              {{ title }}
            </p>
            <p class="text-subtitle-1 mb-0">{{ text }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn depressed @click="reject">
          {{ rejectButton }}
        </v-btn>
        <v-btn depressed :color="status" @click="confirm">
          {{ confirmButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "mdi-information",
    },
    status: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "Text",
    },
    confirmButton: {
      type: String,
      default: "Yes",
    },
    rejectButton: {
      type: String,
      default: "No",
    },
  },
  computed: {
    showConfirmationDialogue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {};
  },

  methods: {
    confirm() {
      this.$bus.$emit("confirmed");
      this.showConfirmationDialogue = false;
    },
    reject() {
      this.$bus.$emit("rejected");
      this.showConfirmationDialogue = false;
    },
  },
};
</script>
