import { leaseDriverBonusExcel, leaseDriver } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";


export default (axios) => ({
    getleaseDriverBonusExceleList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(leaseDriverBonusExcel.leaseDriverBonusExcelBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getLeaseDriverList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(leaseDriver.leaseDriverBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getSapPayoutDataForEach(params = {}) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${leaseDriver.leaseDriverBase}get_sap_payout_data_of_each_leg/`, {
              params: params,
            })
            .then((res) => {
              resolve(handleResponse(res));
            })
            .catch((err) => {
              reject(handleError(err));
            });
        });
      },
    getLeaseDriverObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${leaseDriver.leaseDriverBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateLeaseDriver(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${leaseDriver.leaseDriverBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getLeaseDriverLogs(id) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${leaseDriver.leaseDriverBase}${id}/lease_driver_logs/`)
            .then((res) => {
              resolve(handleResponse(res));
            })
            .catch((err) => {
              reject(handleError(err));
            });
        });
      },

});