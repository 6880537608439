import { sapBonusPayout } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getSAPDriverProcessingList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(sapBonusPayout.sapBonusPayoutBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addSAPDriverProcessData(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(sapBonusPayout.sapBonusPayoutBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getSAPDriverPayoutDataList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(sapBonusPayout.sapDriverBonusPayoutBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadDAExcel(payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${sapBonusPayout.sapDriverBonusPayoutBase}download_sap_bonus_excel_sheet`,
          {
            params: payload,
            responseType: "blob",
          }
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  bulkSendSAPBonusData(sap_data_ids) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${sapBonusPayout.sapDriverBonusPayoutBase}bulk_send_to_sap/`, {
          sap_data_ids,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  UpdateSAPbonus(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${sapBonusPayout.sapDriverBonusPayoutBase}lease_sap_bonus_update/`,
          payload
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
